.dialog {
  :global {
    .MuiDialog-paper {
      width: 100%;
      max-width: 250px;
      border-radius: 10px;
    }

    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      gap: 15px;
      text-align: center;
    }
  }

  .formButton {
    width: 100%;
  }
}

.dialogTablet {
  :global {
    .MuiDialog-paper {
      max-width: 315px;
    }

    .MuiTypography-body5 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .formButton {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
