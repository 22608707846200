.timeSelectList {
  max-height: 20rem;
}

.dialog {
  .dialogPaper {
    width: 35.2rem;
    position: relative;
    padding: 2rem;
    display: flex;
    gap: 1.5rem;
    border-radius: 1rem;
    background: #fff;
    box-shadow: 0rem 0.2rem 0.8rem 0rem rgba(0, 0, 0, 0.05);

    .header {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;

      .leftHeaderSide {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;

        .select {
          width: 17.4rem;
          .selectIcon {
            width: 1rem;
          }
        }
      }

      .closeIcon {
        cursor: pointer;
        width: 1.5rem;
      }
    }

    .dateSelectorRow {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 1rem;
      align-items: center;

      > svg {
        width: 2rem;
        height: 2rem;
      }

      .openDateSelectorButton {
        display: flex;
        height: 3rem;
        padding: 0.7rem 1.5rem;
        align-items: center;
        cursor: pointer;

        border-radius: 0.3rem;
        border: 0.1rem solid #d1d1d1;
        background: #fff;
      }

      .dateSelectorContainer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
      }

      .dateSelector {
        z-index: 1;
        position: absolute;
        top: 11rem;
        width: 30.6rem;
        padding: 1rem 1.3rem;
        border-radius: 1rem;
        background: #fff;
        box-shadow: 0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.2);

        & :global {
          .MuiPickersCalendarHeader-root {
            margin: 0;
            padding: 0;

            .MuiPickersCalendarHeader-labelContainer {
              color: #81cfdd;
              font-family: Open Sans;
              font-size: 1.8rem;
              font-style: normal;
              font-weight: 700;
              height: 100%;
            }

            .MuiPickersArrowSwitcher-root {
              button {
                width: 3rem;
                height: 3rem;
                padding: 0;
                color: #81cfdd;

                &.Mui-disabled {
                  opacity: 0.2;
                }

                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .MuiDayCalendar-root {
            .MuiDayCalendar-header {
              span {
                color: black;
                font-size: 1.4rem;
                font-weight: 700;
              }
            }

            .MuiDayCalendar-monthContainer {
              border: 0.05rem solid #dfdfdf;

              .MuiDayCalendar-weekContainer {
                margin: 0;

                .MuiPickersDay-root {
                  width: 4rem;
                  height: 3.95rem;
                  border-radius: 0;
                  border: 0.05rem solid #dfdfdf;
                  margin: 0rem;
                  font-size: 1.4rem;
                  color: black;

                  &.MuiPickersDay-dayOutsideMonth {
                    color: #919191;
                  }

                  &.Mui-disabled {
                    color: #919191;
                  }

                  &.Mui-selected {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }

    .timeSelectorRow {
      display: grid;
      grid-template-columns: auto 1fr auto 1fr;
      gap: 1rem;
      align-items: center;

      > svg {
        width: 2rem;
        height: 2rem;
      }

      .select {
        .selectIcon {
          width: 1rem;
        }
      }
    }

    .row {
      gap: 0.7rem;
      display: flex;
      flex-direction: column;
    }

    .invalidPhone {
      > div > button {
        border-color: red;
      }
    }

    .phoneInput {
      width: 100%;

      &.invalidPhoneInput {
        border-color: red;
      }
    }

    .errorMessage {
      color: #ff3d3d;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4rem;
    }

    .textareas {
      height: 12.5rem;

      > textarea {
        height: 9.5rem !important;

        padding: 1.5rem;
      }
    }

    .radioGroup {
      display: grid;
      grid-template-columns: 1fr 1fr;

      svg {
        width: 1.5rem;
        height: 1.5rem;

        &[data-testid='RadioButtonUncheckedIcon'] {
          color: #d1d1d1;
        }
      }
    }
  }
}
