.root {
  position: absolute;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 80%);

  &.small {
    width: 100%;
    height: 100%;
  }

  .spinner {
    color: #81cfdd;
  }
}

.spinnerFixed {
  position: fixed;
}
