.title {
  margin-bottom: 0.7rem;
}

.audioLevel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .progressBar {
    background-color: #e8e8e8;
    height: 0.5rem;
    width: 100%;
    border-radius: 0.5rem;

    .progress {
      height: 100%;
      background-color: #00d05e;
      border-radius: 0.5rem;
      transition: width 0.1s linear;
    }
  }
}
