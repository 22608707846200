@use '../../../../../../styles/variables';

.dialog {
  :global {
    .MuiPaper-root {
      width: 35rem;
      border-radius: 1rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .row {
      display: flex;
      gap: 0.7rem;

      .asterisk {
        color: #ff3c3c;
        font-size: 1.2rem;
        font-weight: 600;
      }
    }

    .assignSeat {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .dialog {
    :global {
      .MuiPaper-root {
        width: 100%;
        margin: 4rem;
      }
    }
  }
}
