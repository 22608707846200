    .checkboxContainer {
        justify-content: center;

        margin-top : -0.5rem;
        margin-left: 1.5rem;

        .checkbox {
            padding-top   : 0;
            padding-bottom: 0;
        }

        .checkboxIcon {
            font-size: 1.4rem;
        }
    }