.dialog {
    :global {
        .MuiDialog-paper {
            width: 100%;
            max-width: 35rem;
        }

        .MuiDialogContent-root {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }
    }

    .row {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
    }

    .formButton {
        width: 100%;
    }
}
