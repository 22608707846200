.menuIcon {
    padding: 0;
    background-color: transparent !important;
    align-items: center;
    display: flex;
}

.dotMenu {
    :global {
        .MuiListItemButton-root {
            padding: 7px 10px;
        }
    }

    z-index: 1301;
    width: 110px;
}