@use '../../styles/variables';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 3rem;

  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;

    .chatButton button {
      width: 5rem;
      height: 5rem;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    grid-template-rows: auto 1fr;
    gap: 1.5rem;
    position: relative;

    .chatButton {
      position: absolute;
      bottom: 6.3rem;
      right: 0.5rem;
    }
  }
}
