@use 'sass:map';
@use 'styles/variables';

.profile {
  display: grid;
  gap: 0.8rem;
  grid-template-columns: auto auto auto;
  align-items: center;
  cursor: pointer;

  .texts {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    align-items: center;
    gap: 0 1.1rem;

    .name {
      font-weight: 600;
      text-transform: uppercase;
      grid-column: 1 / 3;
    }
  }

  .profilePicture {
    width: 3.3rem;
    height: 3.3rem;
  }

  .arrowIcon {
    width: 1rem;
  }
}

.menu {
  width: 20.4rem;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgb(0 0 0 / 5%);

  .item {
    .menuButton {
      width: 100%;
      padding: 1rem 1.5rem;
      color: #333;
      font-size: 1.5rem;
      font-weight: 400;
      gap: 1rem;
      justify-content: start;
      letter-spacing: -0.336px;

      :global {
        .MuiButton-startIcon {
          margin: 0;
          font-size: 1.5rem;

          svg {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
