@use '../../../../styles/variables';

.hide {
  display: none;
}

.container {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: 5px;
  isolation: isolate;
  background-color: #d1d1d1;

  .screenSharingInfoContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .screenSharingInfo {
      padding: 3px 10px;
      color: white;
      background: rgb(0 0 0 / 75%);
      border-radius: 5px;
    }
  }

  .infoContainer {
    position: absolute;
    z-index: 2;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    background: transparent;

    .recordingIndicator {
      position: absolute;
      bottom: 0;
      display: flex;
      height: 28px;
      align-items: center;
      padding: 0.1em 0.3em 0.1em 0;
      font-size: 1.2rem;

      .circle {
        width: 12px;
        height: 12px;
        margin: 0 0.6em;
        animation: 1.25s pulsate ease-out infinite;
        background: red;
        border-radius: 100%;
      }
    }

    .infoRowBottom {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 5px;

      .identity {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 3px 10px;
        background: rgb(0 0 0 / 50%);
        border-radius: 5px;

        .star {
          font-size: 1rem;
          color: #81cfdd;
        }

        .micOff {
          font-size: 1rem;
          color: #ff3d3d;
        }
      }

      .addInfo {
        margin-left: auto;
        height: 25px;
        display: flex;
        align-items: center;
        gap: 1rem;

        .dotMenuToggle {
          height: 25px;
          width: 25px;
        }

        .dotMenuIcon {
          font-size: 1.6rem;
        }

        .nq {
          // display: none;
          height: 2.5rem;
          width: 2.5rem;
          padding: 0.4rem 0.3rem;
          border-radius: 0.5rem;
          background: rgba(0, 0, 0, 0.5);

          svg {
            width: unset;
            height: unset;
          }
        }
      }
    }
  }

  .innerContainer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.container:hover {
  .infoContainer .infoRowBottom .addInfo .nq {
    display: block;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) and (orientation: landscape) {
  .container {
    .infoContainer {
      .infoRowBottom {
        bottom: unset;
        top: 0;
      }
    }
  }
}

@keyframes pulsate {
  0% {
    background: #a90000;
  }

  50% {
    background: #f00;
  }

  100% {
    background: #a90000;
  }
}
