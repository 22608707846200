@use '../../../../../../styles/variables';

.container {
  padding: 3rem;
  max-width: 57rem;
  height: calc(100% - 4.6rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .innerContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .row {
      gap: 0.7rem;
    }

    .createButton {
      width: 100%;
    }
  }

  .link {
    margin-top: auto;
    padding-top: 1.5rem;

    a {
      color: #179aff;
      text-decoration: underline;
      margin: 0;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    padding: 2rem;

    .innerContainer {
      gap: 1.5rem;
    }
  }
}
