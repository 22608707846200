@use '../../../../styles/variables';

.list {
  gap: 0.5rem !important;
  display: flex;
  flex-direction: column;
  max-height: 30rem;
}

.select {
  svg {
    width: 1.3rem;
    right: 1rem;
  }

  :global {
    .MuiSelect-select {
      padding: 1.5rem;
      opacity: 1;
    }

    .MuiOutlinedInput-notchedOutline {
      border-radius: 0.5rem;
      border: 0.1rem solid #e8e8e8;
    }
  }

  .menuItem {
    border-bottom: 0.1rem solid #e8e8e8;
  }
}

.item {
  display: grid;
  gap: 0.5rem 1.5rem;
  text-align: start;
  grid-template-columns: min-content auto;

  .avatar {
    grid-row: 1 / span 2;
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
  }

  .organization {
    grid-row: 1 / 1;
    text-transform: uppercase;
    font-weight: 700;
    color: #333333;
  }

  .additionalInfo {
    display: flex;
    gap: 3rem;
    height: 1.6rem;

    .count {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      & > svg {
        height: 1.6rem;
        width: 1rem;
        color: #979797;
      }
    }
  }

  .organization, .additionalInfo {
    grid-column: 2;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .list {
    max-height: 28rem;
  }
}
