.menu {
    width: 269px;
    padding: 20px;
    border-radius: 5px;

    display: flex;
    gap: 2rem;
    flex-direction: column;

    & > div + div {
        overflow-y: scroll;
    }
}