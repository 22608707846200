@use "sass:map";
@use "styles/variables";


.main {
    display: grid;
    height: 100%;
    min-height: 100vh;
    padding: 0 !important;
    gap: 0;
    grid-template-rows: auto 1fr;
}

.container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}
