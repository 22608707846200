.durationCountdown {
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: inline-flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;

  border-radius: 5px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}

.whatYourClientSeesLabel {
  position: absolute;
  top: 1em;
  left: 50%;
  color: #fff;
  transform: translate(-50%, 0.1em);
  z-index: 222;
}

.container {
  width: 100%;
  height: 100%;
  background-color: black;
  position: relative;
  overflow: hidden;

  canvas {
    width: 100%;
    height: 100%;
  }
  .object {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    transition-timing-function: linear;
    transition-duration: 1s;
    opacity: 1;

    .objectInner {
      width: 100%;
      left: 0;
      top: 0;
      color: inherit;
      position: absolute;
      transition-timing-function: linear;
      transition-duration: 1s;
      padding: 0.1rem;

      svg {
        width: 100%;
        height: 100%;
        color: inherit;
      }
    }
  }
}
