.message {
  max-width: 270px;
  border-radius: 20px 20px 20px 0;
  padding: 8px 13px;
  background-color: #f2f2f2;
  width: fit-content;
  word-break: break-word;

  a {
    color: blue;
    margin: 0;
  }
}

.message.fileMessage {
  border-radius: 10px;
  padding: 8px 15px;
  background-color: #f2f2f2;
}

.fileMessage {
  display: flex;
  cursor: pointer;

  .fileIcon {
    margin-right: 10px;

    svg {
      font-size: 32px;
    }
  }

  :global {
    .MuiTypography-root {
      overflow-wrap: anywhere;
    }
  }
}

.messageMy {
  margin-left: auto;
  border-radius: 20px 20px 0 20px;
  border: 1px solid #81cfdd;
  padding: 8px 15px;
  background-color: #ffffff;
}

.messageMy.fileMessage {
  background-color: #ffffff;
}
