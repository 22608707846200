.main {
    height: 100%;

    :global {
        .MuiTabs-root {
            min-height: 46px;
        }

        .MuiTab-root {
            min-width: 119px;
            min-height: 46px;
            padding: 0 20px;
            color: #393534;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: -0.384px;

            &.Mui-selected {
                background: #FFF;
                color: #81CFDD;
                font-weight: 700;
            }
        }

        .MuiTabs-indicator {
            display: none;
        }
    }

    .content {
        display: flex;
        height: 100%;
        padding: 30px;
        gap: 30px;
        grid-template-columns: 1fr 24rem;

        .contentWrapper {
            width: 100%;
            padding: 0;
        }

        .preview {
            height: 100%;

            .videoPreview {
                height: 16rem;
            }
        }
    }
}
