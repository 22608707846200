@use '../../../../styles/variables';

.room {
  display           : grid;
  position          : relative;
  height            : 100%;
  padding           : 30px 30px 25px;
  gap               : 20px;
  grid-template-rows: 1fr auto;
  background-color  : #e7e7e7;

  .logo {
    position: absolute;
    top     : 0.5rem;
    right   : 3rem;
  }

  .mainContent {
    position: relative;
    display : flex;
    overflow: hidden;
  }

  .controls {
    display              : grid;
    grid-template-columns: 1fr 1fr 1fr;
    position             : relative;

    .waitingQueue {
      position: absolute;
      bottom  : 0;
      left    : -7px;
    }
  }

  .chat {
    z-index : 100;
    bottom  : 9rem;
    position: absolute;
    left    : 3rem;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .room {
    padding: 30px;
    gap    : 29px;

    .controls {
      justify-content      : space-between;
      grid-template-columns: auto auto;
      position             : unset;
    }

    .waitingQueue {
      position: absolute;
      top     : 90px;
      bottom  : auto;
      left    : -7px;
    }

    .waitingQueueGalery {
      top: 25rem;
    }

    .waitingQueueEMDR {
      top: 6rem;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) and (orientation: landscape) {
  .room {
    grid-template-rows: 1fr;
    padding           : 1rem;
    gap               : 1rem;

    .logo {
      display: none;
    }

    .controls {
      position       : absolute;
      bottom         : 2rem;
      width          : 100%;
      justify-content: center;
      gap            : 2rem;

      .waitingQueue {
        position: unset;
      }
    }

    .chat {
      width           : 33.5rem;
      height          : 100%;
      background-color: #fff;
      top             : 0;
      right           : 0;
      left            : unset;
      z-index         : 1001;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .room {
    padding: 3rem 1rem 1.5rem;
    gap    : 15px;

    .logo {
      right: calc(50% - 6rem);
    }

    .controls {
      justify-content: center;
      gap            : 1.5rem;

      .waitingQueue {
        position: unset;
      }
    }

    .chat {
      width           : calc(100% - 2rem);
      position        : absolute;
      border-radius   : 5px;
      margin          : 1rem;
      box-shadow      : 0px 2px 8px rgba(0, 0, 0, 0.05);
      background-color: #fff;
      z-index         : 1000;
      bottom          : 0;
      left            : 0;
    }
  }
}