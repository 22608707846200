@use '../../../../styles/variables';

.container {
  position  : relative;
  box-sizing: border-box;
  padding   : 0 22px;

  :global {
    .swiper-wrapper {
      box-sizing: border-box;
    }
  }

  &.hideParticipantList {
    display: none;
  }

  .swiper {
    width  : 100%;
    height : 150px;
    padding: 0;

    .slide {
      width     : 150px;
      max-height: 150px;
      padding   : 0;
    }
  }
}

.containerHideNav {
  height: unset;
}

@media (min-width: map-get(variables.$breakpoints, 'md')) {
  .container {
    // padding: 22px 0 22px;
    padding: 0;

    .swiper {
      width : 205px;
      height: 100%;

      .slide {
        width     : 100%;
        max-height: 130px;
      }
    }
  }

  .navigationEl.navigationPrevEl {
    top      : 0;
    right    : 50%;
    left     : auto;
    bottom   : auto;
    height   : 20px;
    width    : 18px;
    transform: rotate(90deg) translateY(-10px);
  }

  .navigationEl.navigationNextEl {
    top      : auto;
    right    : 50%;
    bottom   : 0;
    height   : 18px;
    transform: rotate(90deg) translateY(-10px);
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .container {
    height: 17.5rem;

    .swiper {
      height: 17.5rem;

      .slide {
        width     : 17.5rem;
        height    : 17.5rem;
        max-height: 17.5rem;
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) and (orientation: landscape) {
  .container {
    padding: 22px 0 22px;
    height : auto !important;

    .swiper {
      height: 100%;

      .slide {
        width : 16.8rem;
        height: 12rem;
      }
    }
  }

  .navigationEl.navigationPrevEl {
    top      : 0;
    right    : 50%;
    left     : auto;
    bottom   : auto;
    height   : 20px;
    width    : 18px;
    transform: rotate(90deg) translateY(-10px);
  }

  .navigationEl.navigationNextEl {
    top      : auto;
    right    : 50%;
    bottom   : 0;
    height   : 18px;
    transform: rotate(90deg) translateY(-10px);
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    height: 15rem;

    .swiper {
      height: 15rem;

      .slide {
        width     : 15rem;
        height    : 15rem;
        max-height: 15rem;
      }
    }
  }
}

.navigationHidden {
  display: none;
}

.navigationDisabled {
  display: none;
}

.navigationDisabledClass {
  display: none;
}

.navigationLock {
  cursor : inherit;
  opacity: 0.5;
}

.navigationEl {
  position : absolute;
  z-index  : 10;
  top      : 50%;
  cursor   : pointer;
  transform: translateY(-50%);
}

.navigationNextEl {
  right: 0;
}

.navigationPrevEl {
  left: 0;
}