@use '../../styles/variables';

.discountInput {
  display: grid;
  gap: 0.7rem;

  .discountCode {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 1rem;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .seatsCountSelector {
    .seatsCount {
      height: 3.5rem;
    }
  }
}
