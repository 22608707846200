@use '../../../../styles/variables';

.main {
  height: 100%;

  .welcomePopupActive {
    display: none;
  }
  :global {
    .MuiTabs-root {
      min-height: 46px;
    }

    .MuiTab-root {
      min-width: 119px;
      min-height: 46px;
      padding: 0 20px;
      color: #393534;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.384px;

      &.Mui-selected {
        background: #fff;
        color: #81cfdd;
        font-weight: 700;
      }
    }

    .MuiTabs-indicator {
      display: none;
    }

    .MuiDivider-root {
      width: 100%;
    }
  }

  .content {
    display: flex;
    height: 100%;
    padding: 3rem;
    gap: 30px;

    .contentWrapper {
      width: 100%;
      padding: 0;
    }

    .preview {
      height: 100%;
      margin: 0;

      .videoPreview {
        height: 16rem;
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .main {
    position: relative;

    .availableMark {
      position: absolute;
      right: 2rem;
      top: 1.3rem;
    }

    .content {
      padding: 1.5rem 2rem 2rem;
    }
  }
}
