@use "sass:map";
@use "./variables";

html,
body {
    padding: 0;
    margin: 0;
    background: map-get(variables.$colors, "grey-light");
}

a {
    margin: 10px;
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.MuiPaper-root {
    background-color: #F1F3F7;
}

.MuiButton-root {
    text-transform: none;
}

.MuiPaper-elevation {
    background-color: #FFF;
    border-radius: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    border: none;
    box-shadow: none;
}

::-webkit-scrollbar-thumb {
    background: #828282;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #828282;
}