.dialog {
  :global {
    .MuiDialog-paper {
      width: 100%;
      max-width: 40rem;
    }
  }

  .asterisk {
    color: #ff3c3c;
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.password_popup_header_box {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .password_main_title {
    color: #393534;
    font-weight: 700;
  }
  .closeIcon {
    color: #81cfdd;
    font-size: 20px;
    cursor: pointer;
  }
}
.typography {
  color: #393534;
  margin-top: 10px;
  margin-bottom: 7px;
}
.button {
  width: 100%;
  margin-top: 20px;
}
