.dialog {
  :global {
    .MuiDialog-paper {
      width: 100%;
      max-width: 36rem;
    }

    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      text-align: center;
    }
  }

  .dialogContent {
    display: flex;
    gap: 1.5rem;
  }

  .buttonsContaine {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    width: 100%;
  }
}