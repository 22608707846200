@use '../../../../styles/variables';

.form {
  gap    : 2rem;
  display: grid;

  h1 {
    font-weight: 600;
    font-size  : 36px;
    line-height: 49px;
  }

  .row {
    gap: 0.6rem;

    .asterisk {
      color      : #ff3c3c;
      font-size  : 1.2rem;
      font-weight: 600;
    }
  }

  .errorMessage {
    text-align: start !important;
  }

  .forgotPassword {
    color : #81cfdd;
    cursor: pointer;
  }

  .formButton {
    width: 100%;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .form {
    gap: 1.5rem;

    .row {
      gap: 0.6rem;

      .asterisk {
        color      : #ff3c3c;
        font-size  : 1.2rem;
        font-weight: 600;
      }
    }
  }
}