.popoverBox {
  display: flex;
  flex-direction: column;
  min-width: 17.6rem;

  span {
    color: #393534;
    width: 100%;
  }

  button {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    text-align: left;

    &:hover {
      color: #81cfdd;
    }

    svg {
      width: auto;
      max-width: 1.8rem;
      font-size: 1.8rem;
    }
  }
}
