@use '../../../../styles/variables';

.menu {
  width: auto;
  min-width: 147px;
  padding: 10px;
  border-radius: 5px;

  & > div + div {
    margin-top: 20px;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) and (orientation: landscape) {
  .menu {
    width: 35.5rem;
    margin: auto;
  }
}

