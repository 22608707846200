@use '../../../../styles/variables';

.container {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 10px;
  flex-direction: row;
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .container {
    flex-direction: column;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) and (orientation: landscape) {
  .container {
    flex-direction: row;
  }
}
