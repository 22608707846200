.dialog {
  :global {
    .MuiDialog-paper {
      width: 25rem;
    }

    .MuiDialogContent-root {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
      text-align: center;
    }
  }

  .radioGroup {
    width: 100%;
    display: flex;
    gap: 0.3rem;

    .check {
      svg {
        width: 1.6rem;
        height: 1.6rem;

        &[data-testid='RadioButtonUncheckedIcon'] {
          color: #d1d1d1;
        }
      }
    }
  }

  .buttons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
}
