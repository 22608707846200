@use '../../styles/variables';

.cross {
  font-size: 1.3rem;
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .cross {
    font-size: 2rem;
  }
}
