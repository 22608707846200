@use '../../../../styles/variables';

.form {
  gap: 2rem;
  display: grid;

  .formButton {
    width: 100%;
  }

  .rowWithTwoInputs {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }

  .row {
    gap: 0.6rem;

    :global {
      .MuiInputBase-root.Mui-disabled {
        background-color: #e8e8e8;
      }
    }

    .asterisk {
      color: #ff3c3c;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .form {
    gap: 1.5rem;

    .rowWithTwoInputs {
      display: grid;
      gap: 1.5rem;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
    }

    .row {
      gap: 0.6rem;

      .asterisk {
        color: #ff3c3c;
        font-size: 1.2rem;
        font-weight: 600;
      }
    }

    .captcha {
      transform: scale(0.85);
      transform-origin: 0 0;
    }
  }
}
