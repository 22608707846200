@use '../../../../styles/variables';

.groupChat {
  width: 100%;
  height: 100%;

  :global {
    .MuiCardHeader-action {
      margin: 0;

      .MuiButtonBase-root {
        height: 32px;
      }
    }

    .MuiCardHeader-root {
      background: white;
      padding-top: 14px;

      .MuiTypography-root {
        font-size: 1.4rem;
      }
    }

    .MuiCardContent-root {
      padding: 0px 5px 20px 10px;
      height: calc(100% - 65px);
    }
  }

  .close {
    font-size: 13px;
  }

  .messagesList {
    height: calc(100% - 64px);
    padding-left: 10px;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .groupChat {
    .close {
      font-size: 2rem;
    }
  }
}
