@use '../../../../styles/variables';

@media (max-width: map-get(variables.$breakpoints, 'md')) and (min-width: map-get(variables.$breakpoints, 'sm')) and (orientation: portrait) {
  .container {
    position: absolute;
    width: 34rem;
    border-radius: 1rem;
    margin: 0;
    z-index: 1000;
    bottom: 10.5rem;
    right: 3rem;

    > div:first-child {
      border-radius: 1rem;
    }

    .chat {
      height: 38.8rem;
    }

    .participants {
      height: 40rem;
    }

    .notes {
      height: 62.4rem;
    }

    .backgroundEffects {
      height: 54rem;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) and (orientation: landscape) {
  .container {
    width: 33.5rem;
    height: 100%;
    position: absolute;
    right: 0;
    border-radius: 5px;
    box-shadow: 0px 2px 8px rgb(0, 0, 0, 0.05);
    background-color: #fff;
    z-index: 1000;

    > div:first-child {
      border-radius: 5px;
      height: 100%;
    }
  }

  .container.containerSmallerWidth {
    width: 28.6rem;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    width: calc(100% - 2rem);
    max-height: calc(100vh - 2rem);
    position: absolute;
    border-radius: 5px;
    margin: 1rem;
    box-shadow: 0px 2px 8px rgb(0, 0, 0, 0.05);
    background-color: #fff;
    z-index: 1000;
    bottom: 1.5rem;

    > div:first-child {
      border-radius: 5px;
    }

    .chat {
      height: 40rem;
      max-height: calc(100vh - 2rem);
    }

    .participants {
      height: 30rem;
    }

    .notes {
      height: 30rem;
    }

    .settings {
      height: auto;
      max-height: 47rem;
    }

    .backgroundEffects {
      height: 35rem;
    }
  }
}
