@use '../../../styles/variables';

.dialog {
  :global {
    .MuiDialog-paper {
      width: 100%;
      max-width: 30rem;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 1rem;
    }

    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      text-align: center;
    }
  }

  .icon {
    width: 5rem;
    height: 5rem;
  }

  .formButton {
    width: 100%;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .dialog {
    :global {
      .MuiDialog-paper {
        max-width: 31.5rem;
      }
    }
  }
}
