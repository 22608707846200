@use '../../styles/variables';

.container {
  display: flex;
  width: 100%;

  .innerContainer {
    margin: auto;
    display: flex;
    gap: 8rem;

    img {
      width: 25.3rem;
      height: 25.3rem;
    }

    .texts {
      width: 55rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      h1, h2 {
        font-weight: 700;
      }

      button {
        width: 30rem;
      }
    }
  }
}

@media (min-width: map-get(variables.$breakpoints, 'sm')) and (max-width: map-get(variables.$breakpoints, 'md')) {
  .container {
    .innerContainer {
      gap: 5rem;

      img {
        width: 26.2rem;
        height: 26.2rem;
      }

      .texts {
        width: 30rem;

        button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    .innerContainer {
      flex-direction: column;
      gap: 3rem;
      align-items: center;

      img {
        width: 20rem;
        height: 20rem;
      }

      .texts {
        width: 30rem;
        text-align: center;

        button {
          width: 100%;
        }
      }
    }
  }
}
