@use '../../../../styles/variables';

.container {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  border: 0.1rem solid #979797;
  background: #d1d1d1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  canvas {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }

  .settings {
    position: absolute;
    top: 1rem;
    margin: auto;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 1.5rem;

    border-radius: 0.5rem;
    background: #777777;
    box-shadow: 0rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.1);

    .devider {
      width: 0;
      height: 2rem;
      border-radius: 0.5rem;
      border-right: 0.1rem solid rgba(255, 255, 255, 0.5);
    }

    .settingsItem {
      width: 2rem;
      height: 2rem;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
      }

      .selectColorIcon {
        width: 2rem;
        height: 2rem;
        border-radius: 0.2rem;
        border: 0.2rem solid #fff;

        &.active {
          border: 0.2rem solid #42e2ff;
        }
      }
    }
  }

  .arrow {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: -0.5rem;
    background: #777777;
    transform: rotate(45deg);
  }

  .lineSizeSettings {
    position: absolute;
    top: 7.5rem;
    left: calc(50% - 30.7rem);
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;

    border-radius: 0.5rem;
    background: #777777;
    box-shadow: 0rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.1);

    &.isPatient {
      left: calc(50% - 23.7rem);
    }

    .arrow {
      left: 2rem;
    }

    .lineSizeSettingsItem {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .colorSettings {
    position: absolute;
    top: 7.5rem;
    left: calc(50% - 30.7rem);
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    padding: 1.5rem 1.1rem;

    border-radius: 0.5rem;
    background: #777777;
    box-shadow: 0rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.1);

    &.isPatient {
      left: calc(50% - 23.7rem);
    }

    .arrow {
      left: 6rem;
    }

    .colorSettingsItem {
      width: 2.2rem;
      height: 2.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      border-radius: 50%;

      &.active {
        border: 0.2rem solid #81cfdd;

        div {
          border: 0.2rem solid #ffffff;
          width: 1.8rem;
          height: 1.8rem;
        }
      }

      div {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 0.1rem solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  .figureSettings {
    position: absolute;
    top: 7.5rem;
    left: calc(50% - 22rem);
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;

    &.isPatient {
      left: calc(50% - 15rem);
    }

    border-radius: 0.5rem;
    background: #777777;
    box-shadow: 0rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.1);

    .arrow {
      left: 13.5rem;
    }

    .figureSettingsItem {
      width: 2rem;
      height: 2rem;
      cursor: pointer;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .zoomSettings {
    position: absolute;
    top: 7.5rem;
    left: calc(50% - 7rem);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem;

    border-radius: 0.5rem;
    background: #777777;
    box-shadow: 0rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.1);

    &.isPatient {
      left: calc(50% - 3rem);

      .arrow {
        left: 15.8rem;
      }
    }

    .arrow {
      left: 13rem;
    }

    .zoomPercent {
      width: 4.1rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      border: 1px solid #fff;
    }

    .button {
      width: 2rem;
      height: 2rem;
      cursor: pointer;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    .slider {
      width: 11rem;

      :global {
        .MuiSlider-root {
          width: 11rem;
          padding: 0;
        }

        .MuiSlider-rail {
          height: 0.5rem;
          color: #ffffff;
        }

        .MuiSlider-track {
          color: #ffffff;
        }

        .MuiSlider-thumb {
          color: #ffffff;
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
  }

  .showZoomInfo {
    position: absolute;
    bottom: 1.5rem;
    left: calc(50% - 3rem);

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

    border-radius: 0.5rem;
    background: #777777;
    box-shadow: 0rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.1);

    span {
      width: 4rem;
      text-align: center;
    }
  }

  .toggleFullScreen {
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    z-index: 1;
  }
}

@media (min-width: map-get(variables.$breakpoints, 'sm')) and (hover: none) {
  .container {
    .lineSizeSettings {
      left: calc(50% - 26.7rem);

      &.isPatient {
        left: calc(50% - 19.7rem);
      }
    }

    .colorSettings {
      left: calc(50% - 26.7rem);
      padding: 1.1rem 1.5rem;

      &.isPatient {
        left: calc(50% - 19.7rem);
      }
    }

    .figureSettings {
      left: calc(50% - 18rem);

      &.isPatient {
        left: calc(50% - 11.5rem);

        .arrow {
          left: 14rem;
        }
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    .settings {
      gap: 1.5rem;
    }

    .lineSizeSettings {
      left: calc(50% - 17.2rem) !important;
    }

    .colorSettings {
      left: calc(50% - 17.2rem) !important;
      gap: 1.4rem;
      padding: 1.1rem 1.3rem;

      .arrow {
        left: 5.5rem;
      }
    }

    .figureSettings {
      left: calc(50% - 5.5rem) !important;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

      .arrow {
        left: 7.5rem;
      }
    }

    .additionalSettings {
      position: absolute;
      top: 7.5rem;
      left: calc(50% + 9.2rem);
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding: 1rem;

      border-radius: 0.5rem;
      background: #777777;
      box-shadow: 0rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.1);

      .arrow {
        left: 1.5rem;
      }

      .settingsItem {
        width: 2rem;
        height: 2rem;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .devider {
        width: 2rem;
        height: 0;
        border-radius: 0.5rem;
        border-top: 0.1rem solid rgba(255, 255, 255, 0.5);
      }
    }
  }
}
