@use '../../../../styles/variables';

.layoutContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  .registerImage {
    width: 124px;
    margin: 0 auto;
  }
  .layoutItem {
    display: flex;
    text-align: left;
    gap: 10px;
    color: #393534;
    svg {
      font-size: 1.8em;
      font-weight: bold;
    }
  }
  .link {
    color: #81cfdd;
    font-style: italic;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .layoutContainer {
    gap: 10px !important;
    .layoutItem {
      svg {
        font-size: 1.2em;
        margin-top: 2px;
      }
    }
    p {
      font-size: 14px !important;
    }
    .registerImage {
      width: 88px;
    }
    .welcomeText {
      font-size: 20px;
    }
  }
}
