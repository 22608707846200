@use '../../../../styles/variables';

.form {
  gap: 2rem;
  display: grid;

  .switcher {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0.3rem;

    background: #f3f3f3;
    border-radius: 0.5rem;

    .switcherItem {
      height: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .switcherItemActive {
      background: #ffffff;
      box-shadow: 0px 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;

      cursor: default;
    }

    .discount {
      position: absolute;
      right: 1rem;
      top: -0.9rem;
      padding: 0.3rem 0.5rem;
      background: #81cfdd;
      border-radius: 0.5rem;
      line-height: 0.8rem;

      span {
        font-size: 0.8rem;
        line-height: 1.1rem;
        font-weight: 700;
        color: #ffffff;
      }
    }
  }

  .row {
    display: grid;
    gap: 0.7rem;
  }

  .pricePerMonth {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  .discountCode {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 1rem;
  }

  .calculationsRow {
    display: flex;
    justify-content: space-between;
  }

  .buttons {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .toFreeTrial {
    text-align: center;

    span {
      color: #43adff;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media (min-width: map-get(variables.$breakpoints, 'sm')) and (max-width: map-get(variables.$breakpoints, 'md')) {
  .form .row .roomNameContainer .roomNameLink {
    height: 3.5rem;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .form {
    .benefits {
      h2 {
        font-weight: 700;
        text-align: center;
        margin-bottom: 1.5rem;

        .link {
          font-style: italic;
          color: #81cfdd;
        }
      }

      .dataText {
        padding: 0;
        margin: 0;
        gap: 1rem;
        display: flex;
        flex-direction: column;

        li {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 1.1rem;

          .dataCheckIcon {
            svg {
              height: 1.8rem;
              width: 1.8rem;
            }
          }
        }
      }
    }

    .row .roomNameContainer .roomNameLink {
      height: 3.5rem;
    }
  }
}
