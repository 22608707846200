.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .container {
    background: #ffffff;
    border-radius: 0.5rem;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));

    .containerTop,
    .containerBottom {
      padding: 2rem;
      gap: 1.5rem;
      display: flex;
      flex-direction: column;
    }
    
    .containerTop {
      background-color: #f8f8f8;
    }

    .containerBottom {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }

    .row {
      display: grid;
      gap: 0.7rem;
    }

    .pricePerMonth {
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }

    .devider {
      width: 100%;
      height: 0;
      border-top: 0.1rem solid #d1d1d1;
    }

    .calculationsRow {
      display: flex;
      justify-content: space-between;
    }

    .card {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      padding: 1rem 1.5rem;
      background: #ffffff;
      border: 0.1rem solid #d1d1d1;
      border-radius: 0.3rem;
    }

    .toAnnual {
      text-align: center;

      b {
        font-weight: 700;
      }
    }

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;

      .manageStaff {
        grid-column: 1 / span 2;
      }
    }
  }
}
