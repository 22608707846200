.emailTooltipHref {
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
}

.form {
  width: 100%;
  max-width: 51rem;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .row {
    position: relative;
    gap: 0.7rem;

    .email {
      background-color: #e8e8e8;
    }
  }

  .asterisk {
    color: #ff3d3d;
  }
}
