@use '../../styles/variables';

.patientQueue {
  :global {
    .MuiCardHeader-action {
      margin-right: 0;
    }

    .MuiListItemSecondaryAction-root {
      right: 1.6rem;
    }
  }

  .cardContent {
    padding: 1.5rem 2rem;
    max-height: 40rem;
    overflow-y: scroll;
  }

  .tabletCardContent {
    max-height: 20rem;
    padding: 15px 30px;
    overflow-y: scroll;
  }

  .mobileCardContent {
    max-height: 290px;
    padding: 15px 20px;
  }

  // .bell {
  //   font-size: 2.4rem;
  // }

  .chat {
    position: absolute;
    bottom: 5rem;
    left: 3rem;
    z-index: 1;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) and (orientation: landscape) {
  .patientQueue .chat {
    width: 50%;
    left: 0;
    bottom: 0;
    height: 100%;
    position: fixed;
    z-index: 2;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .patientQueue .chat {
    width: calc(100% - 3.2rem);
    left: 1.6rem;
    bottom: 2rem;
    position: fixed;
    z-index: 2;
  }
}
