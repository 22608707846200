@use '../../../../styles/variables';

.container {
  display: flex;

  .innerContainer {
    flex-direction: column;
    align-content: baseline;
    border-radius: 10px;

    .toggleButtonContainer {
      display: flex;
      height: 45px;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0 15px;
      border-radius: 10px;
      gap: 15px;
    }

    .pointer {
      cursor: pointer;
    }

    .toggleButtonContainerActive {
      min-width: 280px;
    }

    .toggleButtonContainerPinned {
      min-width: 160px;
    }

    .listContainer {
      overflow: auto;
      max-height: 268px;
      padding: 0 20px;
      margin-top: 15px;
      margin-bottom: 14px;
    }
  }

  .chat {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .container {
    .innerContainer {
      display: flex;
      flex-direction: column-reverse;

      .toggleButtonContainerActive {
        .pointer {
          font-size: 1.8rem;
        }
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) and (orientation: landscape) {
  .container {
    .innerContainer {
      .toggleButtonContainer {
        padding: 0 0.7rem;
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    .innerContainer {
      .toggleButtonContainer {
        padding: 0 0.7rem;
      }
    }
  }
}
