@use '../../styles/variables';

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 24px;
  padding: 30px;
  border: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &:focus {
    outline: none;
  }
  .declinedTitle {
    font-weight: 600;
  }
}
.main {
  width: 100%;
  display: flex;

  .leftSide {
    position: relative;
    width: 100%;
    max-width: 60rem;
    background: linear-gradient(161.01deg, #81cfdd 11.62%, rgba(169, 222, 252, 0.51) 115.48%);
    padding-top: 16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.6rem;

    img {
      width: 16.4rem;
      height: 14rem;
    }

    .content {
      gap: 2.6rem;
      width: 100%;
      max-width: 40rem;
      padding: 0 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h1 {
        margin-bottom: 3rem;
        font-size: 3.6rem;
        font-weight: 700;
        line-height: 4.9rem;
      }

      button {
        color: #81cfdd;
        background-color: #ffffff;
        width: 100%;
      }

      span > a {
        margin: 0;
        text-decoration: underline;
        font-weight: bold;
      }
    }

    .backgroundCircle1,
    .backgroundCircle2,
    .backgroundCircle3 {
      position: absolute;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);
    }

    .backgroundCircle1 {
      width: 37rem;
      height: 37rem;
      left: -20.9rem;
      top: -10rem;
    }

    .backgroundCircle2 {
      width: 14rem;
      height: 14rem;
      left: -4.3rem;
      top: 30rem;
    }

    .backgroundCircle3 {
      width: 21rem;
      height: 21rem;
      left: 36rem;
      top: 60rem;
    }
  }

  .rightSide {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    align-items: center;
    padding: 13rem 1.2rem;
    text-align: center;

    .loginContainer {
      width: 57rem;
      border-radius: 1rem;
      overflow: hidden;
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
      padding: 5rem 9rem;
      background-color: #ffffff;
    }

    & > span > a {
      font-weight: 600;
      color: #81cfdd;
      margin: 0;
    }
  }
}

@media (min-width: map-get(variables.$breakpoints, 'sm')) and (max-width: map-get(variables.$breakpoints, 'md')) {
  .main {
    flex-direction: column;

    .leftSide {
      max-width: none;
      padding-top: 5rem;
      padding-bottom: 5rem;
      flex-direction: row;
      justify-content: center;
      gap: 6rem;

      img {
        width: 21.4rem;
        height: 18.2rem;
      }

      .content {
        width: 100%;
        max-width: 30rem;
        padding: 0;
        gap: 2rem;

        h2 {
          font-weight: 700;
        }
      }

      .backgroundCircle1 {
        width: 23rem;
        height: 23rem;
        left: -5rem;
        top: 18rem;
      }

      .backgroundCircle2 {
        width: 15rem;
        height: 15rem;
        left: unset;
        right: 5rem;
        top: -6rem;
      }

      .backgroundCircle3 {
        width: 9.5rem;
        height: 9.5rem;
        left: unset;
        right: 0;
        top: 10.5rem;
      }
    }

    .rightSide {
      padding: 5rem 1.2rem;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .main {
    flex-direction: column;

    .leftSide {
      max-width: none;
      padding-top: 3rem;
      padding-bottom: 3rem;
      gap: 1.5rem;

      img {
        width: 15.3rem;
        height: 13rem;
      }

      .content {
        max-width: 31.5rem;
        width: 100%;
        gap: 1.5rem;
      }

      .backgroundCircle1 {
        width: 14rem;
        height: 14rem;
        left: -6rem;
        top: 2rem;
      }

      .backgroundCircle2 {
        width: 6.8rem;
        height: 6.8rem;
        left: -2.5rem;
        right: unset;
        top: 39rem;
      }

      .backgroundCircle3 {
        width: 7.3rem;
        height: 7.3rem;
        left: unset;
        right: 0;
        top: 15.5rem;
      }
    }

    .rightSide {
      padding: 3rem 1.5rem;
      grid-gap: 1.5rem;

      .loginContainer {
        width: 100%;
        padding: 3rem 2rem;
      }
    }
  }
}
