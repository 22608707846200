@use '../../../../styles/variables';

.tooltip {
  max-width: 25rem;
  width: auto;
}

.container {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  position: relative;
  height: 100%;

  .main {
    height: calc(100% - 4.6rem);
    padding: 3rem;
    display: grid;
    gap: 2rem;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: auto;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .headerSeats {
        gap: 5rem;
        flex-direction: row;
        align-items: center;
      }

      .inviteButton {
        height: fit-content;
      }
    }

    .table {
      overflow: scroll;

      .tableHeader {
        width: 76.5rem;
        display: grid;
        // grid-template-columns: 12rem 18rem 18rem 10rem 4.6rem;
        grid-template-columns: 12rem 18rem 17.5rem 6rem 4.2rem 4.9rem 1.6rem;
        gap: 2rem;
        border-top: 0.1rem solid #d1d1d1;
        border-bottom: 0.1rem solid #d1d1d1;
        padding: 1rem 0;
      }

      .tableItem {
        width: 76.5rem;
        display: grid;
        // grid-template-columns: 12rem 18rem 18rem 10rem 4.6rem 1.6rem;
        grid-template-columns: 12rem 18rem 17.5rem 6rem 4.2rem 4.9rem 1.6rem;
        gap: 2rem;
        border-bottom: 0.1rem solid #e8e8e8;
        padding: 1rem 0;

        > p {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .moreOptionsButton {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }

    .pagination {
      display: grid;
      grid-template-columns: 15.5rem 6.3rem 12.8rem;
      grid-gap: 3rem;
      align-items: center;
      margin: auto;

      .rowsPerPage {
        display: flex;
        gap: 1rem;
        align-items: center;

        .select {
          height: 3rem;

          :global {
            .MuiSelect-select {
              font-size: 1.2rem;
            }
          }

          svg {
            width: 1rem;
          }
        }
      }

      .navigationButtons {
        > button {
          width: 3.2rem;
          height: 3.2rem;
          padding: 0;

          svg {
            font-size: 3.2rem;
          }
        }
      }
    }

    .notAvailable {
      padding: 1.5rem;
      max-width: 51rem;
      width: 100%;

      border: 0.1rem solid #d1d1d1;
      border-radius: 0.3rem;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .container .main {
    .header {
      .headerSeats {
        gap: 0.5rem;
        flex-direction: column;
      }
    }

    .table {
      min-height: 30rem;
    }

    .pagination {
      display: grid;
      grid-template-columns: 15.8rem 6.3rem;
      grid-template-rows: auto auto;
      grid-gap: 1rem 3rem;
      align-items: center;
      justify-content: center;
      margin: auto;

      .rowsPerPage {
        display: flex;
        gap: 1rem;
        align-items: center;

        .select {
          svg {
            width: 1rem;
          }
        }
      }

      .navigationButtons {
        grid-column: 1 / 3;
        margin: auto;
      }
    }
  }
}
