@use '../../styles/variables';

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 3rem;

  .patientQueue {
    width: 28rem;
    height: fit-content;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) and (min-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;

    .patientQueue {
      width: auto;
    }

    .videoPreviewContainer {
      height: 24.8rem;

      .videoPreview {
        height: 100%;
        max-height: 100%;
        width: 100%;
      }
    }

    .waitingRoom {
      grid-column: 1 / span 2;
    }
  }

  .containerRoomNameNull {
    grid-template-rows: 1fr;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    height: fit-content;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    gap: 1.5rem;

    .patientQueue {
      width: auto;
    }

    .videoPreviewContainer {
      height: 12rem;

      .videoPreview {
        height: 100%;
        max-height: 100%;
        width: 50%;
      }

      .videoControls {
        display: flex;
        gap: 1.5rem;
      }
    }

    .notificationSettingsContainer {
      padding: 1rem 2rem;
      background: #ffffff;
      box-shadow: 0rem 0.2rem 0.8rem rgba(0, 0, 0, 0.05);
      border-radius: 0.5rem;
    }
  }

  .containerRoomNameNull {
    grid-template-rows: 1fr;
    height: 100%;
  }
}
