.dialog {
  :global {
    .MuiDialog-paper {
      flex-direction: row;
      border-radius: 10px;
    }

    .MuiDialogTitle-root {
      padding: 0;
      display: flex;
      align-items: center;
    }

    .MuiDialogContent-root {
      padding: 20px;
    }
  }

  .avatar {
    width: 264px;
    height: 264px;

    img {
      object-fit: contain;
    }
  }

  .avatarTablet {
    width: 302px;
    height: 288px;
  }

  .form {
    display: flex;
    width: 100%;
    max-width: 224px;
    height: 100%;
    flex-direction: column;
    gap: 15px;

    .welcome {
      word-break: break-word;
    }

    .errorHelper {
      position: absolute;
      // bottom: -17px;
      margin: 0;
    }

    .formButton {
      margin-top: auto;
    }

    .formButtonTablet {
      height: 35px;
      line-height: 20px;
      font-size: 15px;
    }
  }
}

.dialogMobile {
  :global {
    .MuiDialog-paper {
      width: 100%;
      flex-direction: column;
      max-width: 315px;
    }

    .MuiDialogTitle-root {
      padding: 20px 0 15px;
    }

    .MuiDialogContent-root {
      padding: 20px;
    }
  }

  .avatarMobile {
    width: 100px;
    height: 100px;
    min-height: unset;
    margin: auto;
    border-radius: 5px;
  }

  .formMobile {
    max-width: unset;
  }
}
