@use '../../../../styles/variables';

.layoutContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  .registerImage {
    width: 124px;
    margin: 0 auto;
  }

  .layoutItem {
    display: flex;
    text-align: left;
    gap: 10px;
    color: #393534;
    svg {
      font-size: 1.8em;
      font-weight: bold;
    }
  }
}
@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .layoutContainer {
    .createRoomText {
      font-size: 20px;
    }
    .createRoomDesc {
      font-size: 15px;
    }
  }
}
