.container {
    :global {
        .MuiDivider-root {
            width: 100%;
        }
    }

    display: flex;
    flex-direction: column;
    gap: 15px;

    .micIcon {
        font-size: 16px;
    }

    .videoIcon {
        font-size: 17px;
    }
}
