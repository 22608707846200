.dialog {
    :global {
        .MuiDialog-paper {
            width: 100%;
            max-width: 288px;
        }

        .MuiDialogContent-root {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            text-align: center;
        }
    }
}
