.deviceButton {
  min-width: 45px;
  height: 45px;

  .icon {
    font-size: 2.6rem;
  }
}

.deviceButtonOpen {
  width: auto;
  border-radius: 3rem;
  padding: 1.5rem;
  margin-right: 0;

  .icon {
    font-size: 2rem;
    margin-right: 1rem;
  }
}
