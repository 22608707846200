.select {
  height: 3rem;
  padding: 1re 1.5rem;

  border-radius: 0.3rem;
  border: 0.1rem solid #d1d1d1;
  background: #fff;

  color: black;

  svg {
    width: 10px;
  }
}
