@use '../../../../styles/variables';

.main {
  height: 100%;
  min-height: unset !important;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 20px;

    p {
      word-break: break-word;
    }

    .messageBtn {
      width: 100%;
      padding: 0.75rem 2rem;
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .emailIcon {
      width: 22px;
      height: 18px;
    }

    .hospitalIcon {
      width: 18px;
      height: 20px;
    }

    .bio {
      max-height: 21rem;
      overflow-y: scroll;
      width: 100%;

      a {
        color: blue;
        margin: 0;
      }
    }

    .preview {
      height: 100%;

      .videoPreview {
        width: 21rem;
        height: 14rem;
      }

      .videoControls {
        gap: 1rem;
        font-size: 3rem;

        span {
          width: 3rem;
          height: 3rem;
        }

        button {
          width: 3rem;
          height: 3rem;

          svg {
            font-size: 1.2rem;
          }
        }
      }
    }

    .chat {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 8rem;
    }
  }
}

.mainTablet {
  min-height: 873px;
  height: none;

  .contentTablet {
    padding: 20px;
  }
}

.mainMobile {
  .contentMobile {
    padding: 20px 20px 15px;
    gap: 36px;

    .bio {
      max-height: 12rem;
    }
  }

  .readMoreBtn {
    width: 148px;
    padding: 0.75rem 2rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .videoPreviewControls {
    width: 3rem;
    height: 3rem;

    svg {
      font-size: 1.5rem !important;
    }
  }
}
