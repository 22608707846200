@use '../../../../styles/variables';

.container {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    .navigationItem {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1.5rem 2rem;
    }
  }
}
