@use '../../../../styles/variables';

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .maxWidth {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .row {
    position: relative;
    gap: 7px;

    .errorHelper {
      position: absolute;
      bottom: -17px;
      margin: 0;
    }

    .checkboxIcon {
      width: 19px;
      height: 19px;
    }

    .textareas textarea {
      padding: 1rem 1.5rem;
    }
  }

  .asterisk {
    color: #ff3d3d;
  }

  .bio {
    height: 13.4rem;

    textarea {
      overflow-y: scroll !important;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) and (min-width: map-get(variables.$breakpoints, 'sm')) {
  .content .maxWidth {
    max-width: 37.5rem;
  }
}
