.bg {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.tooltip {
  width: 21.6rem;
  padding: 1.6rem;
  border-radius: 1rem;
}

.arrowTooltip {
  &::before {
    color: #f3f3f3;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .navigation {
    display: flex;
    justify-content: space-between;

    .action {
      cursor: pointer;
      font-weight: 700;
    }
  }
}
