.dialog {
    :global {
        .MuiDialog-paper {
            width: 100%;
            max-width: 35rem;
        }

        .MuiDialogContent-root {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }
    }

    .error {
        background: #FF3D3D;
        border-radius: 0.3rem;
        text-align: center;
        padding: 0.5rem 1rem;
    }

    .formButton {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.6rem;
    }
}
