.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 20px;

  .header {
    display: flex;
    justify-content: space-between;

    svg {
      font-size: 1.9rem;
      color: #81cfdd;
    }

    .syncIcon {
      animation: 2s linear 0s infinite normal none running rotate;
      color: black;
    }
  }

  .form {
    position: relative;
    overflow: scroll;
    height: 100%;

    .innerForm {
      position: absolute;
      width: 100%;

      .textField {
        width: 100%;
        border: none;
        resize: none;

        &:focus {
          outline: none !important;
        }
      }
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(-360deg);
  }
}
