@use 'sass:map';
@use 'styles/variables';

.main {
  display: grid;
  height: 100%;
  min-height: 100vh;
  padding: 0 !important;
  gap: 30px;
  grid-template-rows: auto 1fr;
}

.mainMobile {
  gap: 15px;
}

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 5rem;
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .container {
    padding-bottom: 3rem;
  }
}
