@use '../../styles/variables';

.registerContainer {
  overflow: hidden;
  padding: 3rem 9rem;
}

@media (min-width: map-get(variables.$breakpoints, 'sm')) and (max-width: map-get(variables.$breakpoints, 'md')) {
  .registerContainer {
    padding: 3rem;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .registerContainer {
    padding: 2rem;
  }
}
