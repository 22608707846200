@use 'styles/variables';

.background {
  background: rgba(0, 0, 0, 0.25);
  width     : 100vw;
  height    : 100vh;
  position  : fixed;
  z-index   : 12000;

  .burgerMenu {
    width           : 41rem;
    height          : 100%;
    padding         : 6.5rem 3rem 6rem;
    background-color: #f3f3f3;
    display         : flex;
    flex-direction  : column;
    gap             : 3rem;
    align-items     : center;
    background-color: #ffffff;
    overflow-y: scroll;

    .header {
      width      : 100%;
      display    : flex;
      align-items: flex-start;

      button {
        padding: 0;

        svg {
          width : 2.6rem;
          height: 2.6rem;
        }
      }
    }

    .profile {
      width                : 100%;
      display              : grid;
      gap                  : 1.7rem;
      grid-template-columns: auto 1fr;
      align-items          : center;
      cursor               : pointer;
      background-color     : #ffffff;
      box-shadow           : 0px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius        : 0.5rem;
      padding              : 1.5rem;

      .profilePicture {
        width : 4.2rem;
        height: 4.2rem;
      }

      .texts {
        display              : grid;
        grid-template-columns: auto 1fr;
        grid-template-rows   : auto auto;
        align-items          : center;
        gap                  : 0 1.1rem;

        .name {
          font-weight   : 600;
          text-transform: uppercase;
          grid-column   : 1 / 3;
        }

        .crownIcon {
          width: 1.8rem;
        }
      }
    }

    .items {
      width                : calc(100% - 4rem);
      display              : grid;
      margin-bottom        : auto;
      grid-template-columns: 1fr;
      gap                  : 0;

      .item {
        height               : 5rem;
        display              : grid;
        grid-template-columns: auto 1fr auto;
        gap                  : 1.5rem;
        padding              : 0.5rem;
        cursor               : pointer;
        align-items          : center;

        &:hover {
          box-shadow   : 0px 2px 8px rgba(0, 0, 0, 0.1);
          border-radius: 0.5rem;
        }

        .right {
          width : 2.2rem;
          height: 2.2rem;
        }
      }
    }

    .footer {
      width                : calc(100% - 4rem);
      display              : grid;
      grid-template-columns: 1fr;
      gap                  : 1rem;

      .devider {
        width        : calc(100% - 1rem);
        height       : 0.1rem;
        border-bottom: 1px solid rgba(136, 136, 136, 0.15);
        opacity      : 0.5;
        margin       : 0.5rem;
      }

      .item {
        display    : flex;
        gap        : 1.5rem;
        padding    : 0.5rem;
        cursor     : pointer;
        align-items: center;

        &:hover {
          box-shadow   : 0px 2px 8px rgba(0, 0, 0, 0.1);
          border-radius: 0.5rem;
        }
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .background {
    .burgerMenu {
      width  : 100%;
      padding: 5.1rem 1.5rem 3rem;

      .header {
        button {
          svg {
            width : 1.9rem;
            height: 1.9rem;
          }
        }
      }

      .items {
        width: calc(100% - 3rem);
      }
    }
  }
}

/* iOS (Safari) and Android hack */
@media screen and (max-width: map-get(variables.$breakpoints, 'sm')) {

  /* Safari hack */
  _::-webkit-full-page-media,
  _:future,
  :root .burgerMenu {
    padding-bottom: 10rem;
  }

  /* Android hack */
  @supports (-webkit-appearance: none) and (not (-moz-appearance: none)) {
    :root .burgerMenu {
      padding-bottom: 10rem;
    }
  }
}