@use '../../../styles/variables';

@media (max-width: map-get(variables.$breakpoints, 'md')) and (min-width: map-get(variables.$breakpoints, 'sm')) and (orientation: portrait) {
  .dialog {
    :global {
      .MuiDialog-container {
        justify-content: flex-end;
        align-items: flex-end;

        .MuiDialog-paper {
          margin: 0;
          bottom: 5rem;
          right: 3rem;
        }
      }
    }
    .settings {
      width: 37.2rem;
      max-height: 80rem;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) or (((max-width: map-get(variables.$breakpoints, 'md')) and (orientation: landscape))) {
  .dialog {
    :global {
      .MuiDialog-container {
        justify-content: center;
        align-items: flex-end;

        .MuiDialog-paper {
          margin: 0;
          bottom: 3rem;
        }
      }
    }
    .settings {
      width: calc(100vw - 2rem);
      max-width: 100%;
      max-height: calc(100vh - 7rem);
    }
  }
}
