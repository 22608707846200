.dialog {
    :global {
        .MuiDialog-paper {
            max-width: 315px;
            border-radius: 10px;
        }
    }

    .checkboxContainer {
        justify-content: center;

        .checkbox {
            padding-top: 0;
            padding-bottom: 0;
        }

        .checkboxIcon {
            font-size: 1.4rem;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .formButtonTablet {
            height: 35px;
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }
}

.dialogMobile {
    .content {
        padding: 20px 22.5px;
    }
}