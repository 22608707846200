.actions {
  display: flex;
  margin-right: -5px;
  gap: 15px;

  & > button {
    padding: 0;
    margin: 0;
  }

  .videoCameraIcon {
    font-size: 2.8rem;
  }

  .chatIconBadge {
    :global {
      .MuiBadge-badge {
        background-color: #ff3c3c;
        color: white;
        width: 12px;
        padding: 0;
        height: 12px;
        min-width: 0;
      }
    }
    
    .chatIcon {
      font-size: 2.2rem;
    }
  }
}

.tooltip {
  width: 100%;
  max-width: 230px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  min-width: 23rem;

  & > div + div {
    margin-top: 15px;
  }
}

.addBtn {
  width: 100%;
  height: 34px;
}

.userName {
  padding-right: 11rem;
  word-wrap: break-word;
}