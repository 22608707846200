@use 'styles/variables';

.container {
    display       : flex;
    flex-direction: column;
    max-width     : 51rem;

    .innerContainer {
        display       : flex;
        flex-direction: column;
        font-size     : 1.5rem;
        gap           : 1rem;
        color         : #393534;

        .seatsContainer {
            display        : flex;
            justify-content: space-between;
            max-width      : 70%;
            gap            : 1rem;
            color          : #393534;

            p {
                white-space: nowrap;
            }
        }

        .seatsCounter {
            font-weight: 700;
        }

        .sectionTitle {
            margin-top: 10px;
        }

        .borderContainer {
            display       : flex;
            flex-direction: column;
            padding       : 1.5rem;
            border        : .1rem solid #d1d1d1;
            border-radius : .3rem;
        }

        .subscriptionContainer {
            display        : flex;
            flex-direction : row;
            gap            : 2rem;
            margin-bottom  : 1rem;
            justify-content: flex-start;
            align-items    : flex-start;
            position       : relative;
        }

        .subscriptionInfo {
            display       : flex;
            flex-direction: column;
            gap           : .5rem;
        }

        .subscriptionType {
            font-weight: 600;
            position   : absolute;
            top        : 0;
            right      : 0;

            text-transform: capitalize;

            &.nonRenewing {
                color: #ff3d3d;
            }
        }

        .iconBorderContainer {
            padding      : 1.5rem 1rem;
            border       : 1px solid #d1d1d1;
            border-radius: 3px;
            position     : relative;
            width        : fit-content;

            .icon {
                width : 3rem;
                height: 1.933rem;
            }
        }

        .cardEnding {
            font-weight: 600;
        }

        .cardExpireDate {
            margin: 10px 0;
        }

        .button {
            background-color: #81CFDD;

            &.nonRenewing {
                background-color: #ff3d3d;
            }
        }
    }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
    .seatsContainer {
        display  : flex;
        max-width: 100% !important;
        color    : #393534;
    }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
    .subLabel {
        font-size: 1.4rem;
    }
}