.organizationInfo {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  padding: 1rem 1.5rem;
  align-items: center;

  &.organizationInfoWithAvatar {
    grid-template-columns: 4.2rem 1fr;
  }

  .organizationPicture {
    width: 4rem;
    height: 4rem;
  }

  .texts {
    display: grid;
    grid-template-columns: 7rem 1fr;
    gap: 0.5rem;
    word-wrap: break-word;
    
    .name {
      grid-column: 1 / 3;
      text-transform: uppercase;
      font-weight: 600;
    }

    .membersCount {
      display: flex;
      gap: 0.5rem;

      .userIcon {
        width: 1rem;
        color: #979797;
      }
    }
  }
}
