@use '../../styles/variables';

.container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.6rem;
  padding: 0;

  .item {
    border: 0.2rem solid #ffffff;
    border-radius: 0.5rem;

    > div {
      height: 8.7rem;
      width: auto;
      border-radius: 0.5rem;
      border: 0.1rem solid #979797;
      background-color: #393534;

      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      p {
        position: absolute;
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 0.4rem;
      }
    }
  }

  .selectedItem {
    border: 0.2rem solid #81cfdd;

    > div {
      border: 0.2rem solid #ffffff;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) and (orientation: landscape) {
  .container {
    .item {
      > div {
        height: 7.4rem;
      }
    }
  }
}
