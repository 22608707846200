.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .container {
    padding: 2rem;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;

    background: #ffffff;
    box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;

    .switcher {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0.3rem;

      background: #f3f3f3;
      border-radius: 0.5rem;

      .switcherItem {
        height: 2.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .switcherItemActive {
        background: #ffffff;
        box-shadow: 0px 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
        border-radius: 0.5rem;

        cursor: default;
      }

      .discount {
        position: absolute;
        right: 1rem;
        top: -0.9rem;
        padding: 0.3rem 0.5rem;
        background: #81cfdd;
        border-radius: 0.5rem;
        line-height: 0.8rem;

        span {
          font-size: 0.8rem;
          line-height: 1.1rem;
          font-weight: 700;
          color: #ffffff;
        }
      }
    }

    .row {
      display: grid;
      gap: 0.7rem;
    }

    .pricePerMonth {
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }

    .devider {
      width: 100%;
      height: 0;
      border-top: 0.1rem solid #d1d1d1;
    }

    .calculationsRow {
      display: flex;
      justify-content: space-between;
    }

    .buttons {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  .containerBenefits {
    h2 {
      font-weight: 700;
      text-align: center;

      i {
        color: #81cfdd;
      }
    }

    ul {
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      li {
        align-items: flex-start;
        display: flex;
        gap: 1rem;

        svg {
          font-size: 1.8rem;
        }
      }
    }
  }
}
