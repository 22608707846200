.container {
    height          : 27px;
    display         : flex;
    align-items     : center;
    justify-content : center;
    background-color: #81CFDD;
    font-size       : 12px;

    & div {
        color: #FFFFFF;
    }

    & span,
    & button {
        font-weight: 700;
    }

    & button {
        font-size     : 12px;
        text-transform: uppercase;
        padding       : 0 !important;
        color         : #FFFFFF !important;
        position      : relative;
        display       : inline-block;
        vertical-align: text-top;
    }

    & button::after {
        content         : "";
        position        : absolute;
        left            : 0;
        bottom          : 2px;
        height          : 1px;
        text-decoration : underline;
        width           : 100%;
        background-color: #FFFFFF;
    }
}

.alert {
    background-color: #FF3D3D;
}

.contentWrapper {
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.textWrapper {
    display    : flex;
    align-items: center;
}

.buttonWrapper {
    display    : flex;
    align-items: center;
}

@media (max-width: 767px) {
    .container {
        height: 50px;
    }

    .contentWrapper {
        flex-direction: column;
    }
}