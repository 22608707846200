@use '../../../../../../styles/variables';

.container {
  height: calc(100% - 4.6rem);
  padding: 3rem;
  display: flex;
  flex-direction: column;
  max-width: 57rem;
  gap: 2rem;

  .row {
    display: flex;
    gap: 0.7rem 0;

    .phone {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 0.7rem;
    }
  }

  .devider {
    height: 0;
    border-top: 1px solid #d1d1d1;
  }

  .text {
    font-weight: 600;
  }

  .field {
    display: grid;
    grid-template-columns: 1fr 2.8rem;
    gap: 0.7rem 0;

    .description {
      grid-column: 1 / 3;
    }
  }

  .headerField {
    padding-right: 1.5rem;
  }

  .mainSettings {
    border: 0.1rem solid #d1d1d1;
    border-radius: 0.5rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .mainSettingsDisabled {
    opacity: 0.5;
  }

  .deleteAccount {
    margin-top: auto;
    color: #81cfdd;
    cursor: pointer;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .container {
    .devider {
      display: none;
    }

    .field {
      grid-template-columns: 1fr 4.1rem;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    height: auto;

    .field {
      .description {
        font-size: 1.4rem;
      }
    }
  }
}
