.dialog {
    :global {
        .MuiDialog-paper {
            width        : 100%;
            max-width    : 250px;
            border-radius: 10px;
        }

        .MuiDialogContent-root {
            display       : flex;
            flex-direction: column;
            gap           : 15px;
            text-align    : center;
        }
    }
}

.dialogTitle {
    p {
        width         : 100%;
        text-align    : center;
        color         : #FF3D3D;
        font-family   : Open Sans;
        font-size     : 20px;
        font-weight   : 600;
        line-height   : 27px;
        letter-spacing: 0px;
        text-transform: capitalize;
    }

    button {
        display: none;
    }
}

.dialogMessage {
    font-family   : Open Sans;
    font-size     : 15px;
    font-weight   : 400;
    line-height   : 20px;
    letter-spacing: 0px;
    text-align    : center;
    color         : #393534;
}