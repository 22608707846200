@use '../../../../styles/variables';

.form {
  gap: 2rem;
  display: grid;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: 600;
    font-size: 3.6rem;
    line-height: 4.9rem;
  }

  .input {
    justify-content: center;

    :global {
      .MuiInputBase-root {
        width: 3.75rem;
        height: 4.5rem;

        .MuiInputBase-input {
          padding: 0;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #81cfdd;
      }
    }
  }

  .buttonsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .form {
    gap: 1.5rem;

    .input {
      gap: 1.5rem;
    }
  }
}
