@use '../../../../styles/variables';

.container {
  height       : 100%;
  background   : #ffffff;
  box-shadow   : 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: .5rem;
  position     : relative;

  .main {
    padding              : 3rem;
    display              : grid;
    gap                  : 2rem;
    grid-template-columns: auto;

    display              : grid;
    gap                  : 2rem;
    grid-template-columns: auto;

    .notAvailable {
      padding  : 1.5rem;
      max-width: 51rem;
      width    : 100%;

      border       : 0.1rem solid #d1d1d1;
      border-radius: 0.3rem;
    }
  }

  .unsubscribe {
    position: absolute;
    bottom  : 2.3rem;
    left    : 1.5rem;

    &:hover {
      color: #81CFDD;
    }
  }
}

@media (max-width: 375px) {
  .container {
    padding-bottom: 3rem;

    .main {
      padding   : 2em;
      min-height: 52vh;
    }

    .unsubscribe {
      bottom: 1rem;
    }
  }
}

@media (min-width: 376px) and (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    padding-bottom: 2rem;

    .main {
      padding   : 2em;
      min-height: 52vh;
    }

    .unsubscribe {
      bottom: 1.5rem;
    }
  }
}

@media (min-width: 376px) and (max-width: 1170px) {
  .container {
    padding-bottom: 2.5rem;

    .unsubscribe {
      bottom: 1rem;
    }
  }
}