@use 'styles/variables';

.freeTrialContainer {
  display       : flex;
  flex-direction: column;
  max-width     : 510px;

  .freeTrialInnerContainer {
    display       : flex;
    flex-direction: column;
    font-size     : 15px;
    gap           : 10px;
    color         : #393534;

    .subscriptionFor {
      margin-top: 10px;
    }

    .borderContainer {
      display       : flex;
      flex-direction: column;
      padding       : 15px;
      border        : 1px solid #d1d1d1;
      border-radius : 3px;
      position      : relative;

      .daysLeft {
        position   : absolute;
        top        : 15px;
        right      : 15px;
        color      : #ff3d3d;
        font-weight: 600;
        font-size  : 15px;
        line-height: 20px;
      }

      .addPaymentMethod {
        margin-bottom: 10px;
      }

      .hipaaLinkTitle {
        font-weight: 600;
      }

      .expireDate {
        margin: 10px 0;
      }

      .warning {
        color  : #ff3d3d;
        padding: 10px 0 5px 0;

        span {
          font-weight: bold;
        }
      }

      .titleAndExpired {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        height         : 2rem;
      }

      .indent {
        margin-bottom  : 1.5rem;
      }

      .expired {
        color      : #ff3d3d;
        font-weight: bold;
        font-size  : 1.5rem;
      }

      &>button {
        background-color: #81CFDD;
      }

      &>.buttonWarning {
        background-color: #ff3d3d;
      }
    }

    .availableContainer {
      display        : flex;
      justify-content: space-between;
      max-width      : 70%;
      gap            : 10px;
      color          : #393534;

      p {
        white-space: nowrap;
      }
    }

    .availableNumber {
      font-weight: 700;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .availableContainer {
    display  : flex;
    max-width: 100% !important;
    color    : #393534;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .expired {
    color      : #ff3d3d;
    font-weight: bold;
    margin     : 10px 0;
  }

  .button {
    background-color: #81cfdd;
  }

  .buttonWarning {
    background-color: #ff3d3d;
  }
}