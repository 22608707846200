.menu {
  width: auto;
  padding: 10px;
  border-radius: 5px;

  & > div + div {
    margin-top: 20px;
  }

  .deviceButton {
    width: 100%;
    height: 16px;
    justify-content: flex-start;
    padding: 0;
    background-color: transparent !important;
    border-radius: unset;
  }

  .shareIcon {
    font-size: 1.6rem !important;
  }
}

.dialog {
  :global {
    .MuiDialog-paper {
        width: 100%;
        margin: 2rem;
        bottom: 0;
            // border-radius: 10px;
    }

    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      gap: 15px;
      text-align: center;
    }
  }
}
