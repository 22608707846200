@use '../../../../styles/variables';

.form {
  gap: 2rem;
  display: grid;

  .formButton {
    width: 100%;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .form {
    gap: 1.5rem;
  }
}
