@use '../../styles/variables';

.icon {
  padding: 0;
  width: 1.6rem;
  height: 1.6rem;

  svg {
    width: 100%;
    height: 100%;
  }
}

.tooltip {
  max-width: 50rem;
  width: auto;
}

.dialog {
  max-width: 30rem !important;
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .icon {
    padding: 0;
    width: 2rem;
    height: 2rem;
  }
}
