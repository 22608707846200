@use '../../styles/variables';

.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  .preview {
    height: 16rem;
    width: 24rem;

    .videoPreview {
      width: 100%;
      height: 100%;
      max-height: unset;
    }

    .controls {
      position: absolute;
      top: 10px;
      right: 10px;
      gap: 1rem;
    }
  }

  .settingsContainer {
    width: auto;

    .settings {
      width: 24rem;
      overflow-y: scroll;
      height: 38rem;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .container .preview {
    height: 24.8rem;
    width: auto;

    .controls {
      gap: 1.5rem;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container .preview {
    display: flex;
    background-color: #fff;
    border-radius: 5px;
    width: auto;
    height: 12rem;

    .controls {
      gap: 1.5rem;
      position: relative;
      top: unset;
      right: unset;

      width: 100%;
      align-items: flex-start;
      justify-content: center;
      padding-left: 19px;
    }
  }
}
