.layoutSelect {
    :global {
        .MuiSelect-outlined {
            .check-icon {
                display: none;
            }
        }
    }
}

.chevron {
    width: 10px;
}