@use '../../styles/variables';

.registerContainer {
  height: calc(100vh - 6rem);
  width: 100%;
  padding: 2.8rem 15rem 5.2rem;

  .content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    border-radius: 1rem;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
    background-color: #ffffff;

    .header {
      height: 6rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      background: #f3f3f3;
      border-radius: 1rem 1rem 0px 0px;

      .headerItem {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        :first-child {
          height: 2rem;
          width: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          border-radius: 50%;
          background-color: #979797;
          color: #ffffff;
        }

        span {
          font-weight: 600 !important;
        }
      }

      .headerItem1 {
        background-color: #81cfdd;
        color: #ffffff;

        :first-child {
          color: #81cfdd;
          background-color: #ffffff;
        }
      }

      .headerItem2 {
        border-radius: 0px 5rem 5rem 0;
        background-color: #81cfdd;
        color: #ffffff;

        :first-child {
          color: #81cfdd;
          background-color: #ffffff;
        }
      }
    }

    .body {
      height: 100%;
      overflow-y: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .leftSide {
        margin: auto 8rem;
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        text-align: center;

        img {
          width: 16.5rem;
          margin: auto;
        }
      }

      .rigthSide {
        height: 100%;
        padding: 3rem 9rem;
        overflow-y: scroll;
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.05);
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .registerContainer {
    padding: 2.8rem 3rem;

    .content {
      .body {
        .leftSide {
          margin: auto 3rem;
        }

        .rigthSide {
          padding: 3rem 3rem;
        }
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .registerContainer {
    height: auto;
    padding: 1.5rem 1.5rem;
    .content {
      .header {
        height: 4.2rem;

        .headerItem {
          :first-child {
            display: none;
          }
        }
      }

      .body {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;

        .leftSide {
          box-shadow: 2rem 2rem 2rem rgba(0, 0, 0, 0.05);
          margin: 0;
          padding: 5rem;
        }

        .rigthSide {
          height: auto;
          padding: 2rem;
        }
      }
    }
  }
}
