@use '../../styles/variables';

.chat {
  width: 300px;
  height: 368px;
  border-radius: 10px !important;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);

  :global {
    .MuiCardHeader-action {
      margin: 0;

      .MuiButtonBase-root {
        height: 32px;
      }
    }

    .MuiCardContent-root {
      padding: 0;
      flex-direction: column;
      display: flex;
      align-content: space-between;
      height: calc(100% - 46px);
    }
  }

  .close {
    font-size: 13px;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
    .chat {
        .close {
            font-size: 2rem;
        }
    }
}
