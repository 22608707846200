@use '../../../styles/variables';

.deviceButton {
  width: 27px;
  height: 27px;

  .icon {
    font-size: 1.5rem;
  }
}

.deviceButtonWithLabel {
  width: 100%;
  height: 20px;
  justify-content: flex-start;
  padding: 0;
  background-color: transparent !important;
  border-radius: unset;

  .icon {
    font-size: 1.7rem;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .deviceButton {
    width: 4.3rem;
    height: 4.3rem;

    .icon {
      font-size: 2.5rem;
    }
  }
}
