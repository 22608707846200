.dialog {
    :global {
        .MuiDialog-paper {
            width: 100%;
            max-width: 250px;
        }

        .MuiDialogContent-root {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            text-align: center;
        }
    }
}
