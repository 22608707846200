.emailTooltipHref {
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
}

.login_main_container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  max-width: 510px;

  .row {
    gap: 0.7rem;
  }
  .email {
    color: #393534;
  }

  .email_textField {
    width: 100%;
    border-color: #d1d1d1;
    background-color: #e8e8e8;
    color: #393534;
    font-size: 12px;
  }
  .inputField {
    color: #393534;
    margin-bottom: 7px;
    margin-top: 20px;
  }
  .flex {
    display: flex;
  }
  .fullWidth {
    width: 100%;
  }
  .changeButton {
    margin-left: 7px;
  }
}
