@use '../../styles/variables';

.container {
  display: grid;
  grid-template-columns: min-content max-content;
  gap: 0.3rem 1.5rem;

  .imageContainer {
    display: flex;
    width: 8rem;
    height: 8rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #d1d1d1;
    border-radius: 3px;
    text-transform: uppercase;

    .image {
      width: 100%;
      height: 100%;
      border-radius: 3px;
    }
  }

  .upload {
    width: fit-content;
  }

  .remove {
    text-align: center;
    cursor: pointer;
  }

  .removeHidden {
    visibility: hidden;
  }

  .errorHelper {
    grid-column: 1 / 3;
    max-width: 25.5rem;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .container {
    .imageContainer {
      width: 9rem;
      height: 9rem;
    }
  }
}
