@use '../../../../../../styles/variables';

.container {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  max-width: 51rem;
  gap: 2rem;

  .asterisk {
    color: #ff3c3c;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .input {
    display: flex;
    gap: 0.7rem;
  }

  .saveButton {
    margin-left: auto;
  }

  :global {
    .MuiInputBase-root.Mui-disabled {
      background-color: #e8e8e8;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, "md")) {
  .container {
    max-width: unset;

    .saveButton {
      width: 100%;
    }
  }
}