@use '../../styles/variables';

.settingsPage {
  display              : grid;
  width                : 100%;
  max-width            : 114rem;
  grid-template-columns: 28rem 1fr;
  gap                  : 3rem;

  .main {
    color        : #ffffff;
    border-radius: 1rem;
    color        : black;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .settingsPage {
    max-width            : none;
    grid-template-columns: 1fr;
    margin-bottom        : auto;

    .main {
      display              : grid;
      grid-template-columns: 1fr;
      gap                  : 1rem;
    }
  }
}