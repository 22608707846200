.container {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 57rem;

  .texts {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
  }

  .paymentMethod {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;

    border-radius: 0.3rem;
    border: 0.1rem solid #d1d1d1;
    background: #fff;

    div:first-child {
      display: grid;
      grid-template-columns: 5rem 1fr auto;
      gap: 2rem;

      .image {
        width: 5rem;
        height: 5rem;

        img {
          object-fit: contain;
          margin: auto;
        }
      }

      .paymentMethodInfo {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        ul {
          padding-left: 1.8rem;
          margin: 0;

          ::marker {
            font-size: 1rem;
            line-height: 1.6rem;
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 1rem;
      }
    }
  }
}
