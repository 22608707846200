@use '../../../styles/variables';

.dialog {
  :global {
    .MuiDialog-paper {
      width: 100%;
      max-width: 25rem;
    }

    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      gap: 15px;
      text-align: center;
    }
  }
}

@media (min-width: map-get(variables.$breakpoints, 'sm')) and (max-width: map-get(variables.$breakpoints, 'md')) {
  .dialog {
    :global {
      .MuiDialog-paper {
        max-width: 30rem;
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .dialog {
    :global {
      .MuiDialog-paper {
        max-width: 31.5rem;
      }
    }
  }
}
