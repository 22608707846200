.dialog {
  :global {
    .MuiDialog-paper {
      width: 100%;
      max-width: 503px;
      max-height: 555px;

      .MuiDialogContent-root {
        overflow: scroll !important;
      }
    }
  }

  .dialogContentTitle {
    display: none;
  }

  .boldText {
    display: inline !important;
  }

  .notes {
    white-space: break-spaces;
    word-break: break-word;
  }
}
