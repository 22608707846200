.dialog {
  :global {
    .MuiDialog-paper {
      width: 100%;
      max-width: 35rem;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 1rem;
    }

    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      text-align: center;
    }
  }
  
  .icon {
    font-size: 6rem;
  }

  .formButton {
    width: 100%;
  }
}
