.back {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  .chevronIcon {
    width: 2rem;
    height: 2rem;
  }
}
