.leftMenu {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 1rem;
  margin-bottom: auto;

  .main {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;

    .profileMenu {
      display: flex;
      gap: 1rem;
      padding: 0.5rem;
      background-color: #ffffff;
      border-radius: 1rem;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    }

    .organizationMenu {
      display: flex;
      gap: 1rem;
      padding: 0.5rem;
      background-color: #ffffff;
      border-radius: 1rem;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    }

    .item {
      padding: 1rem 1.5rem;
      border-radius: 0.5rem;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
        background-color: #fafafa;
      }
    }

    .itemSelected {
      background-color: #f3f3f3;

      &:hover {
        background-color: #f3f3f3;
      }
    }
  }
}
