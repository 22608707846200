@use '../../../../../../../../styles//variables';

.dialog {
  :global {
    .MuiDialog-paper {
      width: 100%;
      max-width: 36rem;
    }

    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      text-align: center;
    }
  }

  .dialogContent {
    display: flex;
    gap: 1.5rem;
  }

  .input {
    :global {
      .MuiFormControl-root {
        border-color: #81cfdd;
      }

      .MuiInputBase-root {
        height: 4.5rem;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 0.1rem solid #81cfdd;
      }
    }
  }

  .buttonsContaine {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    width: 100%;
  }
}

@media (min-width: map-get(variables.$breakpoints, 'sm')) and (max-width: map-get(variables.$breakpoints, 'md')) {
  .dialog {
    .input {
      input {
        padding: 0.75rem 1rem;
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .dialog {
    .input {
      input {
        padding: 0 0
      }
    }
  }
}
