@use '../../../../styles/variables';

.form {
  gap: 2rem;
  display: grid;

  .skipButton {
    color: #81cfdd;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      background-color: unset;
    }
  }

  .row {
    gap: 0.6rem;
    text-align: left;

    .asterisk {
      color: #ff3c3c;
      font-size: 1.2rem;
      font-weight: 600;
    }

    .roomNameContainer {
      display: grid;
      grid-template-columns: auto 1fr;

      .roomName {
        :global {
          .MuiInputBase-input::placeholder {
            font-size: 1.2rem;
          }

          .MuiOutlinedInput-notchedOutline {
            border-left: none;
            border-radius: 0 3px 3px 0;
          }
        }
      }

      .roomNameError {
        grid-column: 1 / span 2;
      }

      .roomNameLink {
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.7rem 1.5rem;

        background: #e8e8e8;
        border: 0.1rem solid #d1d1d1;
        border-radius: 0.3rem 0 0 0.3rem;
      }
    }

    .checkbox {
      padding: 0 1.1rem;

      .checkboxIcon {
        width: 1.9rem;
        height: 1.9rem;
      }
    }
  }

  .bio {
    height: 21rem;
  }

  .skipButtonBottom {
    &:hover {
      color: #81cfdd;
    }
  }
}

@media (min-width: map-get(variables.$breakpoints, 'sm')) and (max-width: map-get(variables.$breakpoints, 'md')) {
  .form .row .roomNameContainer .roomNameLink {
    height: 3.5rem;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .form .row .roomNameContainer .roomNameLink {
    height: 3.5rem;
  }
}
