.patientList {
  padding-top: 0;
  padding-bottom: 0;

  .spinner {
    width: 3.5rem !important;
    height: 3.5rem !important;
  }

  & > li + li {
    margin-top: 20px;
  }
}
