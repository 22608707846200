@use '../../styles/variables';

.link {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  gap: 1rem;

  &.withInvite {
    grid-template-columns: 1fr auto auto;
  }

  .textField {
    width: 100%;

    :global {
      .MuiInputBase-root {
        padding-left: 0;
        gap: 1.4rem;

        .MuiInputBase-input {
          padding-left: 0;
          color: #979797;
          -webkit-text-fill-color: #979797;
        }

        .MuiInputBase-input.Mui-disabled {
          background-color: #ffffff;
        }
      }

      .MuiInputAdornment-root {
        max-height: none;
        height: 100%;
        padding: 0 1.5rem;
        border-right: 0.1rem solid #d1d1d1;
        background: #e8e8e8;
        color: #979797;
        font-weight: 400;
        font-size: 1.4rem;
      }
    }
  }

  .errorHelper {
    position: absolute;
    bottom: -17px;
    margin: 0;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .link {
    grid-template-columns: 1fr;

    &.withInvite {
      grid-template-columns: 1fr;
    }
  }
}
