@use '../../styles/variables';

.seatsCountSelector {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0.5rem;
  align-items: center;

  > button {
    width: 2rem;
    min-width: unset;
    height: 2rem;
    padding: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .seatsCount {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    border: 0.1rem solid #d1d1d1;
    border-radius: 0.3rem;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .seatsCountSelector {
    .seatsCount {
      height: 3.5rem;
    }
  }
}
