.actions.actionsWithChat {
  width: 8rem;
}

.actions {
  display: flex;
  margin-right: -0;
  gap: 15px;
  width: 4.7rem;

  & > button {
    padding: 0;
    width: 30px;
  }

  .dotMenu {
    padding: 0;
    background-color: transparent !important;
  }

  .chatIconBadge {
    :global {
      .MuiBadge-badge {
        background-color: #ff3c3c;
        color: white;
        width: 12px;
        padding: 0;
        height: 12px;
        min-width: 0;
      }
    }

    .chatIcon {
      font-size: 2.2rem;
    }
  }
}

.text {
  margin-right: 8rem;
  word-wrap: break-word;
}
