.messages {
  height: 100%;
  padding: 3px 7px 3px 15px;
  overflow-y: scroll;
  margin: 12px 5px 12px 0;

  .messageContainerMy {
    margin-left: auto;
    display: flex;
    flex-direction: column;
  }
}
