.container {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: 5px;
  isolation: isolate;

  .infoContainer {
    position: absolute;
    z-index: 2;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    background: transparent;

    .dotMenu {
      position: absolute;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 5px;

      &-top {
        top: 0;
      }

      &-bottom {
        bottom: 0;
      }

      .nq {
        // display: none;
        height: 2rem;
        width: 2rem;
        padding: 0.4rem 0.3rem;
        border-radius: 0.5rem;
        background: rgba(0, 0, 0, 0.5);

        svg {
          width: 1.35rem;
          height: 1.28rem;
        }
      }

      .dotMenuToggle {
        width: 25px;
        height: 25px;
        margin-left: auto;
      }

      .dotMenuIcon {
        font-size: 1.6rem;
      }
    }

    .infoRowBottom {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      padding: 5px;
      width: 100%;

      .identity {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 3px 10px;
        background: rgb(0 0 0 / 50%);
        border-radius: 5px;
        max-width: 100%;

        span {
          word-wrap: break-word;
          max-width: 100%;
        }

        .star {
          font-size: 1rem;
          color: #81cfdd;
        }

        .micOff {
          font-size: 1rem;
          color: #ff3d3d;
        }
      }
    }
  }

  .innerContainer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.container:hover {
  .infoContainer .dotMenu .nq {
    display: block;
  }
}

.hideParticipant {
  display: none;
}
