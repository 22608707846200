.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 50rem;

  .section {
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      align-self: flex-start;
    }

    .startButton {
      width: 180px;
    }
  }
}

.tooltipArrow {
  right: 4rem;
  left: unset !important;
}