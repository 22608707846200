.container {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #626363;

  .ball {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.4rem;

    border-radius: 50%;
    background-color: white;
    opacity: 0.5;

    &.ball1 {
      animation: 1s linear 0s infinite normal none running move;
    }

    &.ball2 {
      animation: 1s linear 0.2s infinite normal none running move;
    }

    &.ball3 {
      animation: 1s linear 0.4s infinite normal none running move;
    }
  }
}

@keyframes move {
  0% {
    transform: translateY(0);
    opacity: 0.5;
  }
  15% {
    transform: translateY(-1.2rem);
    opacity: 1;
  }
  25% {
    transform: translateY(-1.5rem);
    opacity: 1;
  }
  40% {
    transform: translateY(0);
    opacity: 0.5;
  }
}
