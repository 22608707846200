@use '../../../../styles/variables';

.chatButton {
  display: flex;
  position: relative;

  .chat {
    top: -385px;
    right: 0;
    position: absolute;
  }

  > button {
    margin-left: auto;
    width: 42px;
    height: 42px;
    padding: 0;
    background-color: #81cfdd;
    border-radius: 50%;

    &:hover {
      background-color: #81cfdd;
      opacity: 0.8;
    }

    > svg {
      font-size: 42px;
    }

    .chatIconBadge {
      > svg {
        font-size: 42px;
      }

      :global {
        .MuiBadge-badge {
          background-color: #ff3c3c;
          color: white;
          width: 12px;
          padding: 0;
          height: 12px;
          min-width: 0;
        }
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) and (orientation: landscape) {
  .chatButton {
    .chat {
      width: (50vw);
      left: unset;
      right: 0;
      top: 0;
      bottom: unset;
      height: 100%;
      position: fixed;
      z-index: 2;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .chatButton {
    .chat {
      position: fixed;
      right: 1rem;
      top: unset;
      bottom: 1rem;
      width: calc(100vw - 2rem);
      z-index: 2;
    }
  }
}
