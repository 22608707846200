@use '../../../../../../styles/variables';

.container {
  position: absolute;

  .relative {
    position: relative;

    .mainContainer {
      position: absolute;
      top: -6.8rem;
      display: flex;
      align-items: center;
      padding: 1rem 1.5rem;
      gap: 2rem;
      border-radius: 0.5rem;
      background: #777777;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

      .fontSelector {
        width: 17.2rem;
      }

      .fontSizeSelector {
        width: 8rem;
      }

      .settingsItem {
        width: 2rem;
        height: 2rem;
        cursor: pointer;

        svg {
          width: 100%;
          height: 100%;
        }

        .selectColorIcon {
          width: 2rem;
          height: 2rem;
          border-radius: 0.2rem;
          border: 0.2rem solid #fff;

          &.active {
            border: 0.2rem solid #42e2ff;
          }
        }
      }
    }

    .input {
      position: absolute;
      border: 0.2rem #81cfdd solid;
      background: #ffffff;
      border-radius: 0.3rem;
      padding: 0.2rem;
      line-height: 0;

      textarea {
        width: calc(100% - 0.8rem);
        max-height: 100rem;
        padding: 0;
        resize: none;
        border: none;
        overflow: hidden;
        outline: none;
        word-break: break-all;
      }

      .resize {
        background-color: transparent;
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        right: -1.2rem;
        top: calc(50% - 1.2rem);
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          background-color: #fff;
          border: 0.2rem solid #000;
          width: 1rem;
          height: 1rem;  
        }
      }
    }

    .colorSettings {
      position: absolute;
      top: -11.8rem;
      left: 17rem;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      padding: 1rem 1rem;

      border-radius: 0.5rem;
      background: #777777;
      box-shadow: 0rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.1);

      .arrow {
        position: absolute;
        width: 1rem;
        height: 1rem;
        left: 14.2rem;
        top: 3.5rem;
        background: #777777;
        transform: rotate(45deg);
      }

      .colorSettingsItem {
        width: 2.2rem;
        height: 2.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        border-radius: 50%;

        &.active {
          border: 0.2rem solid #81cfdd;

          div {
            border: 0.2rem solid #ffffff;
            width: 1.8rem;
            height: 1.8rem;
          }
        }

        div {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          border: 0.1rem solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container .relative {
    .mainContainer {
      gap: 1.5rem;

      .fontSelector {
        width: 9.5rem;
      }
    }

    .colorSettings {
      left: 0;
      gap: 1.5rem;

      .arrow {
        left: 22.5rem;
      }
    }
  }
}
