@use '../../styles/variables';

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;

  .row {
    gap: 8px;
  }

  .checkbox {
    padding-top: 0;
    padding-bottom: 0;
  }

  .checkboxIcon {
    font-size: 1.4rem;
  }

  .preview {
    width: 275px;
    height: 178px;
    max-height: 178px;

    & video {
      max-height: 178px;
    }
  }

  .backgroundEffectsListItem {
    > div {
      height: 5.5rem !important;

      p {
        font-size: 1.2rem;
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .container {
    .backgroundEffectsListItem {
      > div {
        height: 8.3rem !important;

        p {
          font-size: 1.5rem;
        }
      }
    }
  }
}
