.input {
  display: grid;
  grid-template-columns: 1fr 2.8rem;
  gap: 1rem;

  .mark {
    height: 1.2rem;
    width: 0.3rem;
    background: #d9d9d9;

    &[data-index='0'] {
      height: 0rem;
    }

    &[data-index='4'] {
      height: 0rem;
    }
  }

  :global {
    .MuiSlider-root {
      height: 0.8rem;
      padding: 0.6rem 0;
    }

    .MuiSlider-rail {
      background: #d9d9d9;
      opacity: 1;
    }

    .MuiSlider-thumb {
      width: 1.8rem;
      height: 1.8rem;
    }

    .MuiSlider-markActive {
      background: #81cfdd;
      opacity: 1;
    }
  }

  .value {
    padding: 0.2rem 0;
    height: 2rem;
    border: 0.1rem solid #81cfdd;
    border-radius: 0.3rem;
    text-align: center;

    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #393534;
  }
}
