.dialog {
  :global {
    .MuiDialog-paper {
      width        : 100%;
      max-width    : 35rem;
      box-shadow   : 0px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 1rem;
    }

    .MuiDialogContent-root {
      display       : flex;
      flex-direction: column;
      align-items   : center;
      gap           : 15px;
      text-align    : center;
    }

    .MuiListItem-root {
      display    : flex;
      align-items: baseline;
      gap        : 1.125rem;

      .MuiSvgIcon-root {
        padding-top: .5rem;
      }
    }
  }

  .icon {
    font-size: 5rem;
  }

  .actions {
    display    : flex;
    align-items: center;
    gap        : 1rem;
    width      : 100%;
  }

  .formButton {
    width        : 100%;
    border-radius: .3rem;
  }
}