@use '../../styles/variables';

.profileContainer {
  max-width: 1200px;
  width: 100%;
  padding: 30px;
  margin: 0 auto;
  .profileInnerContainer {
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  }
  .registerContainer {
    border-radius: 1rem;
    overflow: hidden;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));

    .headerSolid {
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #81cfdd;
      color: #ffffff;
    }

    .header {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .titleContainer {
        height: 6rem;
        background-color: #f3f3f3;

        .titleContainerRound {
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
          border-radius: 0 50px 50px 0px;

          .number {
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
            border-radius: 50%;
            background-color: #979797;
            text-align: center;
            line-height: 2rem;
            font-size: 1.3rem;
            font-weight: 700;
            color: #ffffff;
          }

          :global {
            .MuiTypography-root {
              font-weight: 600;
            }
          }
        }
      }

      .titleContainerSelected {
        .titleContainerRound {
          background-color: #81cfdd;

          .number {
            background-color: #ffffff;
            color: #81cfdd;
          }

          :global {
            .MuiTypography-root {
              color: #ffffff;
            }
          }
        }
      }

      .titleContainerNextSelected {
        background-color: #81cfdd;
      }
    }

    .body {
      background-color: #ffffff;
      .RoomContainer {
        background-color: #ffffff;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .createRoomInner {
          padding: 30px 90px;
        }
        .profileLayout {
          padding: 144px 90px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        }
      }
      .registerContainer {
        background-color: #ffffff;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .registrationFormInner {
          padding: 30px 90px;
        }
        .registerLayout {
          padding: 60px 90px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  @media (max-width: map-get(variables.$breakpoints, 'sm')) {
    .RoomContainer {
      grid-template-columns: 1fr !important;
      .profileLayout {
        padding: 20px !important;
      }
      .createRoomInner {
        padding: 20px !important;
      }
    }
    .registerContainer {
      grid-template-columns: 1fr !important;
      width: 100%;
      .header {
        .titleContainer {
          height: 42px;
        }
      }
      .registrationFormInner {
        padding: 30px 20px !important;
        button {
          max-width: 85px !important;
        }
      }
      .registerLayout {
        padding: 20px !important;
      }
      .headerSolid {
        height: 4.2rem;
      }
    }
    .rightSide {
      padding: 3rem 1.5rem;
      grid-gap: 1.5rem;

      .header {
        .titleContainer {
          height: 4.2rem;
        }
      }

      .body {
        padding: 1.5rem 2rem;
      }
    }
  }
}
