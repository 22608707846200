@use 'sass:map';
@use 'styles/variables';

.outerWrapper {
  display       : flex;
  flex-direction: column;
  background    : #fff;
  box-shadow    : 0 4px 5px rgb(0 0 0 / 5%);

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
}

.headerWrapper {
  background: #fff;
  box-shadow: 0 4px 5px rgb(0 0 0 / 5%);
}

.headerPrimary {
  position       : relative;
  display        : flex;
  height         : 6rem;
  max-width      : 120rem;
  margin         : auto;
  padding        : 0 2rem;
  flex-direction : row;
  align-items    : center;
  justify-content: space-between !important;

  .icon {
    width : 2.4rem;
    height: 2.4rem;
  }

  a {
    margin     : 0;
    line-height: 0;
  }

  .logo {
    width: 19.3rem;
    height: 3.2rem;
  }
}

.headerSecondary {
  margin-top: 1rem;

  &>div {
    width: 50%;
  }
}

.headerPrimaryTablet {
  height: 7rem;

  a {
    margin: auto;
  }
}

.headerPrimaryMobile {
  justify-content: center;
  height         : 50px;
  padding: 0;

  .icon {
    width: 2.1rem;
    height: 2.1rem;
  }

  .logo {
    width : 14rem;
    height: 2.3rem;
  }
}

.burger {
  background-color: #fff;
  width           : 100%;

  :global {
    .bm-menu {
      color: red;
    }
  }
}

.tooltipArrow {
  right: 4rem;
  left: unset !important;
}