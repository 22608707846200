@use '../../styles/variables';

.main {
  width: 100%;
  height: calc(100vh - 6rem);
  padding: 3rem 3rem 5.2rem;

  .container {
    max-width: 114rem;
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    background-color: #ffffff;

    .headerSolid {
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #81cfdd;
      color: #ffffff;
    }

    .header {
      grid-column: 1 / span 2;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      border-radius: 1rem 1rem 0 0;
      overflow: hidden;
      box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
      z-index: 1;

      .titleContainer {
        height: 6rem;
        background-color: #f3f3f3;

        .titleContainerRound {
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
          border-radius: 0 50px 50px 0px;

          .number {
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
            border-radius: 50%;
            background-color: #979797;
            text-align: center;
            line-height: 2rem;
            font-size: 1.3rem;
            font-weight: 700;
            color: #ffffff;
          }

          :global {
            .MuiTypography-root {
              font-weight: 600;
            }
          }
        }
      }

      .titleContainerSelected {
        .titleContainerRound {
          background-color: #81cfdd;

          .number {
            background-color: #ffffff;
            color: #81cfdd;
          }

          :global {
            .MuiTypography-root {
              color: #ffffff;
            }
          }
        }
      }

      .titleContainerNextSelected {
        background-color: #81cfdd;
      }
    }

    .leftSideBenefits {
      width: 100%;
      height: 100%;
      padding: 4rem 9rem;
      display: flex;
      gap: 1rem;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;

      img {
        width: 12.4rem;
        height: 12.7rem;
      }

      h2 {
        font-weight: 700;
        text-align: center;

        .link {
          font-style: italic;
          color: #81cfdd;
        }
      }

      .dataText {
        padding: 0;
        margin: 0;
        gap: 1rem;
        display: flex;
        flex-direction: column;

        li {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 1.1rem;

          .dataCheckIcon {
            svg {
              height: 1.8rem;
              width: 1.8rem;
            }
          }
        }
      }
    }

    .leftSide30DaysFree {
      width: 100%;
      height: 100%;
      padding: 5rem;
      display: flex;
      gap: 1.5rem;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;
      background: linear-gradient(138deg, #def3ff 0%, #dafff8 100%);

      h2 {
        font-size: 2.8rem;
        line-height: normal;
        font-weight: 700;
      }

      h1 {
        font-size: 4.1rem;
        line-height: normal;
        font-weight: 700;
        text-align: center;
      }

      .box {
        width: 100%;
        position: relative;
        margin-top: 4.7rem;

        img {
          top: 0;
          left: 0;
          position: absolute;
          width: 16rem;
          height: 16.6rem;
          z-index: 2;
        }

        .texts {
          top: 5.5rem;
          right: 0;
          position: absolute;
          width: 34rem;
          padding: 3rem 5rem;
          border-radius: 3rem;
          background: #fff;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .comment {
            line-height: 3rem;
          }
        }
      }
    }

    .rightSide {
      box-shadow: -0.2rem 0 0.8rem rgba(0, 0, 0, 0.1);
      overflow-y: scroll;
    }
  }
}

@media (min-width: map-get(variables.$breakpoints, 'sm')) and (max-width: map-get(variables.$breakpoints, 'md')) {
  .main {
    padding: 3rem 3rem 5.2rem;
    height: auto;

    .container {
      .leftSideBenefits {
        padding: 3rem;
      }

      .leftSide30DaysFree {
        padding: 5rem 3rem;

        h2 {
          font-size: 2.4rem;
        }

        h1 {
          font-size: 2.8rem;
        }

        .box {
          margin-top: 3.8rem;

          img {
            width: 17rem;
            height: 17.6rem;
          }

          .texts {
            top: 8rem;
            width: 100%;
            padding: 12rem 5rem 5rem;
          }
        }
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .main {
    padding: 1.5rem 1.5rem 3rem;
    height: auto;

    .container {
      height: auto;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto 1fr;

      .headerSolid {
        height: 4.2rem;
      }

      .header {
        grid-column: 1 / span 1;

        .titleContainer {
          height: 4.2rem;
        }
      }

      .leftSideBenefits {
        padding: 2rem;

        img {
          width: 8.8rem;
          height: 9rem;
        }
      }

      .leftSide30DaysFree {
        padding: 3rem;

        h2 {
          font-size: 2rem;
        }

        h1 {
          font-size: 2.4rem;
        }

        .box {
          height: 41rem;
          margin-top: 1.9rem;

          img {
            width: 11.5rem;
            height: 12rem;
          }

          .texts {
            top: 5.8rem;
            width: 100%;
            padding: 8rem 3rem 3rem;
          }
        }
      }
    }
  }
}
