@use '../../../../styles/variables';

.container {
  width                : 100%;
  height               : 100%;
  display              : grid;
  grid-template-columns: 1fr 33rem;
  gap                  : 1rem;

  .leftSide {
    position      : relative;
    display       : flex;
    flex-direction: column;
    gap           : 1em;

    .emdrContainerOuter {
      height  : 50%;
      position: relative;
      padding : 0 10.9em;

      background   : #d1d1d1;
      border       : 0.1rem solid #979797;
      border-radius: 0.5rem;
    }
  }

  .rightSide {
    position: relative;
    height  : 100%;
    overflow: hidden;

    .emdrSetting {
      display       : flex;
      flex-direction: column;
      gap           : 1.5rem;
      background    : #ffffff;
      box-shadow    : 0px 2px 8px rgba(0, 0, 0, 0.05);
      overflow-y    : auto;
      height        : calc(100% - 12.5rem);
      padding       : 0 2rem;
      margin        : 5.5rem 0 7rem 0;
      padding-bottom: 2rem;

      .settings {
        display              : grid;
        grid-template-columns: 1fr;
        gap                  : 1.5rem;

        .settingsPart {
          display       : flex;
          flex-direction: column;
          gap           : 1.5rem;
        }
      }

      .row {
        display       : flex;
        flex-direction: column;
        gap           : 0.7rem;

        .durationCountdownSettings {
          display        : flex;
          justify-content: space-between;
          align-items: center;

          .emdrSettingsButton {
            padding: 0;
          }
        }
      }

      .presets {
        display              : grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap                  : 0.8rem;

        .preset {
          height       : 2.5rem;
          background   : #ffffff;
          border       : 0.1rem solid #d1d1d1;
          border-radius: 0.3rem;

          text-align : center;
          font-weight: 600;
          font-size  : 1.2rem;
          line-height: 2.5rem;
          color      : #393534;
          cursor     : pointer;

          &.presetActive {
            background: #81cfdd;
            color     : #fff;
            cursor    : unset;
            border    : 0;
          }
        }
      }

      .select5 {
        width                : 100%;
        justify-content      : space-between;
        display              : grid;
        grid-template-columns: 2rem 2rem 2rem 2rem 2rem;

        >div {
          width : 2rem;
          height: 2rem;

          .rotate45 {
            transform: rotate(45deg);
          }

          .rotateMinus45 {
            transform: rotate(-45deg);
          }

          svg {
            width : 100%;
            height: 100%;
            cursor: pointer;
          }
        }
      }

      .devider {
        border-top: rgba(136, 136, 136, 0.15) solid 0.1rem;
      }

      .objectColors {
        display              : grid;
        grid-template-columns: repeat(9, 1fr);
        justify-content      : space-between;

        .color {
          width          : 2.4rem;
          height         : 2.4rem;
          border-radius  : 50%;
          display        : flex;
          align-items    : center;
          justify-content: center;
          cursor         : pointer;

          >div {
            border-radius: 50%;
            width        : 2rem;
            height       : 2rem;
            border       : rgba(0, 0, 0, 0.16) 0.1rem solid;
          }
        }

        .colorActive {
          box-shadow: 0 0 0 .2rem #81cfdd;

          >div {
            width : 2.2rem;
            height: 2.2rem;
            border: .2rem solid #fff;
          }
        }
      }

      .backgroundColors {
        display              : grid;
        grid-template-columns: repeat(7, 1fr);
        justify-content      : space-between;

        .color {
          width          : 3.4rem;
          height         : 2.3rem;
          border-radius  : 0.2rem;
          display        : flex;
          align-items    : center;
          justify-content: center;
          cursor         : pointer;

          >div {
            border-radius: 0.2rem;
            width        : 2.9rem;
            height       : 2rem;
            border       : rgba(0, 0, 0, 0.16) 0.1rem solid;
          }
        }

        .colorActive {
          border: #81cfdd 0.2rem solid;

          >div {
            width : 2.8rem;
            height: 1.7rem;
            border: none;
          }
        }
      }

      .soundTitle {
        display              : grid;
        grid-template-columns: 1fr auto;

        .soundName {
          height       : 1.8rem;
          border       : 0.1rem solid #81cfdd;
          border-radius: 0.3rem;
          padding      : 0.2rem 0.5rem;

          font-weight: 600;
          font-size  : 1rem;
          line-height: 1.4rem;
        }
      }

      .sounds {
        display              : grid;
        grid-template-columns: 4.8rem 2.7rem 2.7rem 2.7rem 2.7rem 2.7rem 2.7rem 2.7rem;
        justify-content      : space-between;

        .sound {
          height         : 2.5rem;
          border         : 0.1rem solid #d1d1d1;
          border-radius  : 0.3rem;
          display        : flex;
          align-items    : center;
          justify-content: center;
          cursor         : pointer;
        }

        .soundActive {
          background: #81cfdd;
          border    : 0;
          color     : #fff;
        }
      }
    }

    .emdrControlsTitle {
      position               : absolute;
      top                    : 0;
      left                   : 0;
      right                  : 0;
      z-index                : 222;
      padding                : 2rem 2rem 1.5rem 2rem;
      background             : #ffffff;
      border-top-left-radius : 0.5rem;
      border-top-right-radius: 0.5rem;
      height                 : 5.6rem;
    }

    .fixedButtons {
      position: absolute;
      bottom  : 0;
      left    : 0;
      right   : 0;
      height  : 7.1rem;

      display              : grid;
      grid-template-columns: 1fr 1fr;
      gap                  : 1rem;

      padding                   : 2rem;
      background                : #ffffff;
      border-bottom-left-radius : 0.5rem;
      border-bottom-right-radius: 0.5rem;
      box-shadow                : 0px -2px 8px 0px #0000001A;

      button {
        padding: .7rem 1.5rem;

        :global {
          .MuiButton-endIcon {
            svg {
              height: 1.5rem;
            }
          }
        }
      }
    }

    .noShadow {
      box-shadow: none !important;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'lg')) {
  .container {
    grid-template-columns: 1fr 36rem !important;

    .emdrSetting {
      .presets {
        .preset {
          height     : 3.5rem !important;
          font-size  : 1.5rem !important;
          line-height: 3.5rem !important;
        }
      }

      .rowWithSlideInput {
        display              : grid !important;
        grid-template-columns: 4.2rem 1fr !important;
        gap                  : 1rem !important;
      }

      .select5 {
        grid-template-columns: 3rem 3rem 3rem 3rem 3rem !important;

        >div {
          width : 3rem !important;
          height: 3rem !important;
        }
      }

      .objectColors {
        .color {
          width : 2.9rem !important;
          height: 2.9rem;

          >div {
            width : 2.5rem !important;
            height: 2.5rem !important;
          }
        }

        .colorActive {
          >div {
            width : 2.3rem !important;
            height: 2.3rem !important;
          }
        }
      }

      .backgroundColors {
        .color {
          width : 4.5rem !important;
          height: 3.3rem !important;

          >div {
            width : 4rem !important;
            height: 3rem !important;
          }
        }

        .colorActive {
          >div {
            width : 3.9rem !important;
            height: 2.7rem !important;
          }
        }
      }

      .sounds {
        grid-template-columns: 5rem 3.2rem 3.2rem 3.2rem 3.2rem 3.2rem 3.2rem 3.2rem;

        .sound {
          height     : 3rem;
          font-weight: 600;
        }
      }

      // .buttons {
      //   gap: 1.5rem;
      // }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .container {
    grid-template-columns: 1fr !important;
    grid-template-rows   : 1fr 41.5rem !important;

    .leftSide {
      padding: 0 !important;

      .text {
        color  : #ffffff !important;
        z-index: 2 !important;
      }
    }

    .emdrSetting {
      height: calc(100% - 13rem) !important;
      margin: 5.5rem 0 7.5rem 0 !important;

      .settings {
        grid-template-columns: 1fr 1fr !important;
        gap                  : 4rem !important;
      }

      .settingsPart:nth-child(2) {
        .row:nth-child(1) {
          order: 5;
        }

        .devider:nth-child(2) {
          order: 2;
        }

        .row:nth-child(3) {
          order: 3;
        }

        .devider:nth-child(4) {
          order: 4;
        }

        .row:nth-child(5) {
          order: 6;
        }

        .devider:nth-child(6) {
          order: 5;
        }

        .row:nth-child(7) {
          order: 1;
        }

        .devider:nth-child(8) {
          order: 8;
        }

        .emdrSettingsButton:nth-child(9) {
          order: 9;
        }
      }

      .row {
        gap      : 1rem !important;
        font-size: 1.5rem !important;
      }

      .presets {
        .preset {
          height     : 3.5rem !important;
          font-size  : 1.5rem !important;
          line-height: 3.5rem !important;
        }
      }

      .rowWithSlideInput {
        display              : grid !important;
        grid-template-columns: 4.2rem 1fr;
        gap                  : 1rem !important;
      }

      .select5 {
        grid-template-columns: 3rem 3rem 3rem 3rem 3rem !important;

        >div {
          width : 3rem !important;
          height: 3rem !important;

          svg {
            width : 3rem !important;
            height: 3rem !important;
          }
        }
      }

      .objectColors {
        .color {
          width : 3rem !important;
          height: 3rem !important;

          >div {
            width : 2.9rem !important;
            height: 2.9rem !important;
          }
        }

        .colorActive {
          border    : none !important;
          box-shadow: 0 0 0 .2rem #81cfdd;

          >div {
            width : 2.8rem !important;
            height: 2.8rem !important;
            border: .2rem solid #fff !important;
          }
        }
      }

      .backgroundColors {
        .color {
          width : 4.36rem !important;
          height: 3rem !important;

          >div {
            width : 3.9rem !important;
            height: 2.7rem !important;
          }
        }

        .colorActive {
          border    : none !important;
          box-shadow: 0 0 0 .2rem #81cfdd;

          >div {
            width : 3.9rem !important;
            height: 2.7rem !important;
            border: .2rem solid #fff !important;
          }
        }
      }

      .sounds {
        grid-template-columns: 5rem 3.2rem 3.2rem 3.2rem 3.2rem 3.2rem 3.2rem 3.2rem !important;

        .sound {
          height     : 3rem !important;
          font-weight: 600 !important;
        }
      }

      .emdrSettingsButton {
        height: 1.5rem !important;
      }
    }

    .emdrControlsTitle {
      height: 5.6rem !important;
    }

    .fixedButtons {
      height: 7.6rem !important;
      gap   : 4rem !important;
    }
  }
}