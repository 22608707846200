@use '../../../../styles/variables';

.container {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .topContainer {
    position: relative;
    height: 26.8rem;
    padding: 2rem;

    .circle1,
    .circle2 {
      box-sizing: border-box;
      border-radius: 50%;
      position: absolute;
      bottom: 0;
    }

    .circle1 {
      width: 160.5rem;
      height: 160.5rem;
      left: calc(50% - 160.5rem / 2 + 0.5px);
      bottom: 1.5rem;
      background: linear-gradient(289deg, #7e7bff -57.13%, #7effe0 68.59%, #81cfdd 199.66%);
      z-index: 1;
    }

    .circle2 {
      width: 162rem;
      height: 162rem;
      left: calc(50% - 162rem / 2 + 0.5px);
      bottom: 0;
      background: linear-gradient(289deg, #eadaff -57.13%, #ccfffc 68.59%, #d0eeff 199.66%);
    }

    .topDescriptionPercentage {
      height: 13rem;
      width: fit-content;
      margin: 3.8rem auto 0;
      color: #ffffff;
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 0.6rem 1.6rem;

      z-index: 2;
      position: relative;

      .percentValue {
        grid-row: 1 / span 2;
        line-height: 12rem;
        font-size: 17.5rem;
        font-weight: 700;
        text-align: left;
      }

      .percent {
        height: 8.4rem;
        line-height: 7.4rem;
        font-size: 11.4rem;
        font-weight: 700;
      }

      .off {
        height: 3.9rem;
        line-height: 3.2rem;
        font-size: 5.1rem;
        font-weight: 700;
      }
    }

    .topDescriptionFlat {
      height: 15.3rem;
      width: fit-content;
      margin: 2.3rem auto 0;
      color: #ffffff;
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 2.9rem 0.9rem;

      z-index: 2;
      position: relative;

      .flatValue {
        height: 9.5rem;
        line-height: 8rem;
        font-size: 12rem;
        font-weight: 700;
        text-align: left;
      }

      .by {
        height: 3.1rem;
        line-height: 2.8rem;
        font-size: 4rem;
        font-weight: 700;
        margin-top: auto;
      }

      .duration {
        grid-column: 1 / span 2;
        height: 2.9rem;
        line-height: 2.3rem;
        font-size: 3.8rem;
        font-weight: 700;
        text-align: center;
      }
    }
  }

  .bottomContainer {
    padding: 4rem 9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;

    .promoCodeBorder {
      height: 11rem;
      width: 100%;
      padding: 1rem;
      background: linear-gradient(289deg, #eadaff -57.13%, #ccfffc 68.59%, #d0eeff 199.66%);
      border-radius: 0.3rem;

      .promoCode {
        width: 100%;
        height: 100%;
        background: linear-gradient(258.76deg, #81cfdd 9.08%, #79afff 173.92%);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-size: 4rem;
        font-weight: 700;
        color: #ffffff;
      }
    }
  }
}

@media (min-width: map-get(variables.$breakpoints, 'sm')) and (max-width: map-get(variables.$breakpoints, 'md')) {
  .container {
    .topContainer {
      height: 24.3rem;

      .topDescriptionPercentage {
        height: 10.3rem;
        gap: 0.2rem 1.5rem;

        .percentValue {
          height: 10.3rem;
          line-height: 9.3rem;
          font-size: 13.7rem;
        }

        .percent {
          height: 6.6rem;
          line-height: 5.8rem;
          font-size: 9rem;
        }

        .off {
          height: 3.4rem;
          line-height: 3.2rem;
          font-size: 4.3rem;
        }
      }

      .topDescriptionFlat {
        height: 13.3rem;
        margin: 2rem auto 0;
        gap: 2.5rem 0.7rem;

        .flatValue {
          height: 8.2rem;

          line-height: 7rem;
          font-size: 11rem;
        }

        .by {
          height: 2.7rem;
          line-height: 2.4rem;
          font-size: 3.6rem;
        }

        .duration {
          height: 2.5rem;
          line-height: 2.1rem;
          font-size: 3.2rem;
        }
      }
    }

    .bottomContainer {
      padding: 3rem;

      .promoCodeBorder {
        height: 8.4rem;
        padding: 0.5rem;

        .promoCode {
          font-size: 3.2rem;
        }
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    .topContainer {
      height: 11.9rem;

      .topDescriptionPercentage {
        height: 5rem;
        gap: 0.1rem 0.7rem;
        margin: 0.6rem auto 0;

        .percentValue {
          height: 5rem;
          line-height: 4.3rem;
          font-size: 6.4rem;
        }

        .percent {
          height: 3.2rem;
          line-height: 2.8rem;
          font-size: 4rem;
        }

        .off {
          height: 1.6rem;
          line-height: 1.4rem;
          font-size: 2rem;
        }
      }

      .topDescriptionFlat {
        height: 6.2rem;
        margin: 0 auto;
        gap: 1rem 0.4rem;

        .flatValue {
          height: 4rem;
          line-height: 3rem;
          font-size: 4.9rem;
        }

        .by {
          height: 1.3rem;
          line-height: 1rem;
          font-size: 1.5rem;
        }

        .duration {
          height: 1.2rem;
          line-height: 0.9rem;
          font-size: 1.4rem;
        }
      }
    }

    .bottomContainer {
      padding: 2rem;

      .promoCodeBorder {
        height: 6.7rem;
        padding: 0.5rem;

        .promoCode {
          font-size: 2rem;
        }
      }
    }
  }
}
