@use '../../../../styles/variables';

.form {
  gap: 2rem;
  display: grid;

  h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
  }

  .row {
    gap: 0.6rem;

    .asterisk {
      color: #ff3c3c;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .buttons {
    display: grid;
    gap: 1rem 1.5rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;

    & > span {
      grid-row: 2 / span 1;
      grid-column: 1 / span 2;
    }
  }

  b {
    color: #81cfdd;
  }

  .icon {
    font-size: 6rem;
    margin: 0 auto;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .form {
    gap: 1.5rem;

    h1 {
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 3.3rem;
    }
  }
}
