.chatInput {
  .typing {
    margin-left: 35px;
    margin-bottom: 7px;
    height: 14px;
  }

  .inputs {
    margin-left: 3px;
    margin-right: 10px;
    display: flex;

    .attachButton {
      width: 32px;
      height: 32px;
      margin-right: 1px;

      :global {
        .MuiSvgIcon-root {
          font-size: 19px;
        }
      }
    }

    .textInput {
      width: 100%;
      margin-bottom: 10px;

      :global {
        .MuiInputBase-root {
          height: 32px;
          border: 1px solid #81cfdd;
          border-radius: 10px;
          padding-right: 0px;

          .MuiInputBase-input {
            padding: 0;
            padding-left: 15px;
          }

          .MuiInputAdornment-root {
            .MuiSvgIcon-root {
              font-size: 18px;
            }
          }
        }

        .MuiOutlinedInput-notchedOutline {
          display: none;
        }
      }
    }
  }
}
