.deviceButton {
    width: 45px;
    height: 45px;

    .icon {
        font-size: 2.7rem;
    }

    .badge {
        :global {
          .MuiBadge-badge {
            width: 1.6rem;
            height: 1.6rem;
            min-width: 0;
            padding: 0;
            background-color: #ff3c3c;
            color: white;
          }
        }
      }
    
}