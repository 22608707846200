@use '../../styles/variables';

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 3rem;

  .patientQueue {
    width: 28rem;
    height: fit-content;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .container {
    grid-template-columns: 1fr;

    .patientQueue {
      width: auto;
    }
  }
}
