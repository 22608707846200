@use '../../../../styles/variables';

.dialog {
  :global {
    .MuiDialog-paper {
      width: 25rem;
      margin: 3rem;
    }

    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      gap: 15px;
      text-align: center;
    }
  }

  .formButton {
    width: 100%;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .dialog {
    :global {
      .MuiDialog-paper {
        width: 100%;
      }
    }
  }
}
