.menu {
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: #fff;
  box-shadow: 0 2px 8px rgb(0 0 0 / 5%);

  > div:first-child {
    padding: 2rem;
  }

  .scrolableContainer {
    padding: 0 2rem 2rem;
    overflow-y: scroll;
  }
}
