@use '../../../../styles//variables.scss';

.container {
  width: 100%;
  height: 100%;
  position: relative;

  .participantContainer {
    position: absolute;
    display: grid;
    width: 100%;
    height: 100%;
    position: relative;
    grid-gap: 10px;
  }

  .participantContainerDesktop,
  .participantContainerTablet {
    &.participantContainer {
      &-4 {
        grid-template-columns: 1fr 1fr;
      }

      &-7,
      &-8,
      &-9 {
        grid-template-columns: repeat(6, 1fr);
      }

      &-3,
      &-14,
      &-16 {
        grid-template-columns: repeat(4, 1fr);
      }

      &-3,
      &-5,
      &-6,
      &-7,
      &-8,
      &-9,
      &-11,
      &-13,
      &-15 {
        & > div {
          grid-column: span 2;
        }
      }

      &-7 {
        & > div {
          &:last-child {
            grid-column-end: -3;
          }
        }
      }

      &-14 {
        & > div {
          &:nth-last-child(2):nth-child(3n + 1) {
            grid-column-end: 3;
          }
        }
      }

      &-3,
      &-10,
      &-11,
      &-14,
      &-15 {
        & > div {
          &:last-child {
            grid-column-end: -2;
          }
        }
      }

      &-15 {
        & > div {
          &:nth-last-child(3):nth-child(2n + 1) {
            grid-column-end: 4;
          }
        }
      }

      &-8 {
        & > div {
          &:nth-last-child(2):nth-child(3n + 1) {
            grid-column-end: 4;
          }

          &:last-child:nth-child(3n - 1) {
            grid-column-end: -2;
          }
        }
      }

      &-13 {
        & > div {
          &:last-child {
            grid-column-end: -4;
          }
        }
      }
    }
  }

  .participantContainerDesktop {
    &.participantContainer {
      &-5,
      &-6 {
        grid-template-columns: repeat(6, 1fr);
      }

      &-10,
      &-12 {
        grid-template-columns: repeat(4, 1fr);
      }

      &-11,
      &-13,
      &-15 {
        grid-template-columns: repeat(8, 1fr);
      }

      &-10 {
        & > div {
          &:nth-last-child(2):nth-child(2n + 1) {
            grid-column-end: 3;
          }
        }
      }

      &-5 {
        & > div {
          &:nth-last-child(2):nth-child(3n + 1) {
            grid-column: span 3;
          }

          &:last-child:nth-child(3n - 1) {
            grid-column: span 3;
          }
        }
      }

      &-11 {
        & > div {
          &:nth-last-child(3):nth-child(2n + 1) {
            grid-column-end: 4;
          }

          &:nth-last-child(2):nth-child(3n + 1) {
            grid-column-end: 6;
          }
        }
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .participantContainer {
    &.participantContainer {
      &-11 {
        grid-template-columns: repeat(6, 1fr);
      }

      &-5,
      &-6 {
        grid-template-columns: repeat(4, 1fr);
      }

      &-10,
      &-12 {
        grid-template-columns: repeat(3, 1fr);
      }

      &-13,
      &-15 {
        grid-template-columns: repeat(8, 1fr);
      }

      &-5 {
        & > div {
          &:last-child {
            grid-column-end: -2;
          }
        }
      }

      &-11 {
        & > div {
          &:nth-last-child(2):nth-child(3n + 1) {
            grid-column-end: 4;
          }
        }
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) and (orientation: landscape) {
  .container {
    .participantContainer {
      overflow: unset;
      overflow-y: scroll;
      grid-template-columns: repeat(6, 1fr);

      &::-webkit-scrollbar {
        width: 0;
      }

      & > div {
        grid-column: span 2;
      }

      &.participantContainer {
        //   &-3,
        //   &-5,
        //   &-7,
        //   &-9,
        //   &-11,
        //   &-13,
        //   &-15 {
        //     & > div:last-child {
        //       grid-column-start: 2;
        //       grid-column-end: 4;
        //     }
        //   }

        &-1,
        &-2,
        &-3 {
          grid-template-rows: repeat(1, 21.6rem);
        }

        &-4,
        &-5,
        &-6 {
          grid-template-rows: repeat(2, 21.6rem);
        }

        &-7,
        &-8,
        &-9 {
          grid-template-rows: repeat(3, 21.6rem);
        }

        &-10,
        &-11,
        &-12 {
          grid-template-rows: repeat(4, 21.6rem);
        }

        &-13,
        &-14,
        &-15 {
          grid-template-rows: repeat(5, 21.6rem);
        }

        &-16 {
          grid-template-rows: repeat(6, 21.6rem);
        }

        &-1 {
          grid-template-columns: 1fr 1fr;
        }
        &-2 {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    .participantContainer {
      overflow: unset;
      overflow-y: scroll;
      grid-template-columns: repeat(4, 1fr);

      &::-webkit-scrollbar {
        width: 0;
      }

      & > div {
        grid-column: span 2;
      }

      &.participantContainer {
        &-3,
        &-5,
        &-7,
        &-9,
        &-11,
        &-13,
        &-15 {
          & > div:last-child {
            grid-column-start: 2;
            grid-column-end: 4;
          }
        }

        &-1 {
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;
        }
        &-2 {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr;
        }
        &-3,
        &-4 {
          grid-template-rows: 1fr 1fr;
        }
        &-5,
        &-6 {
          grid-template-rows: repeat(3, 21.6rem);
        }
        &-7,
        &-8 {
          grid-template-rows: repeat(4, 21.6rem);
        }
        &-9,
        &-10 {
          grid-template-rows: repeat(5, 21.6rem);
        }
        &-11,
        &-12 {
          grid-template-rows: repeat(6, 21.6rem);
        }
        &-13,
        &-14 {
          grid-template-rows: repeat(7, 21.6rem);
        }
        &-15,
        &-16 {
          grid-template-rows: repeat(8, 21.6rem);
        }
      }
    }
  }
}
