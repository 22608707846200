@use '../../../../../../styles/variables';

.dialog {
  :global {
    .MuiPaper-root {
      max-width: 80rem;
      width: 100%;
      min-height: 49.7rem;
      border-radius: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .row {
    display: grid;
    gap: 0.7rem;
  }

  .pricePerMonth {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  .devider {
    width: 100%;
    height: 0;
    border-top: 0.1rem solid #d1d1d1;
  }

  .calculationsRow {
    display: flex;
    justify-content: space-between;
  }

  .leftSide {
    padding: 3rem 5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background: linear-gradient(231.82deg, #ffffff 14.66%, #f3f3f3 97.2%);

    .card {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      padding: 1rem 1.5rem;
      background: #ffffff;
      border: 0.1rem solid #d1d1d1;
      border-radius: 0.3rem;
    }

    .toAnnual {
      margin-top: auto;
      text-align: center;
      cursor: pointer;

      b {
        font-weight: 700;
      }
    }
  }

  .rightSide {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 3rem 5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .title {
      display: flex;
      justify-content: space-between;

      .cross {
        width: 1.3rem;
        padding: 0;

        svg {
          font-size: 1.3rem;
        }
      }
    }

    .buttons {
      margin-top: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      .manageStaff {
        grid-column: 1 / span 2;
      }
    }
  }
}

.tooltip {
  max-width: 25rem !important;
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .dialog {
    :global {
      .MuiPaper-root {
        height: 55rem;
      }
    }

    .rightSide {
      padding: 3rem;

      .title {
        .cross {
          width: 2rem;

          svg {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
