.PhoneMainBox {
  padding: 20px;
  color: #393534;
  .PhoneInnerBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    .CheckedIcon {
      display: flex;
      margin: 0 auto;
      font-size: 7rem;
      color: #81cfdd;
    }
    .PhoneSuccsessTitle {
      font-weight: 700;
    }
  }

  .asterisk {
    color: #ff3c3c;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .flexContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    button {
      margin-top: 20px;
      flex: 1;
    }
  }
  .typography {
    color: #393534;
    margin-top: 10px;
    margin-bottom: 7px;
  }
  .changePhone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .typography {
      margin-top: 10px;
    }
    .closeIcon {
      color: #81cfdd;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .enterPhone {
    margin-top: 15px;
    text-align: center;
  }

  .phoneNumber {
    margin-top: 5px;
    font-weight: bolder;
  }
  .desc {
    margin-top: 15px;
    line-height: 1.4em;
    text-align: center;
  }
  .cancel {
    display: flex;
    padding: 0;
    margin-top: 10px;
  }
}

.resend {
  u {
    cursor: pointer;
  }
}
