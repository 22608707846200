.container {
  width: 330px;
  min-width: 330px;
  margin-left: 10px;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 8px rgb(0, 0, 0, 0.05);
  background-color: #fff;
  z-index: 1;

  > div:first-child {
    border-radius: 5px;
    height: 100%;
  }
}
