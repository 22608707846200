.dialog {
    :global {
      .MuiDialog-paper {
        width: 100%;
        max-width: 35rem;
      }
  
      .MuiDialogContent-root {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        text-align: center;
      }
    }
  
    .icon {
      width: 6rem;
      height: 6rem;
      color: #FF3D3D;
      transform: rotate(180deg);
    }
  
    .buttons {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
  