.messageDate {
  margin-bottom: 3px;
}

.messageDate {
  margin-left: auto;

  .author {
    margin-right: 10px;
  }
}
