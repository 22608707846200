.container {
  position: relative;

  :global {
    .MuiPopper-root {
      width: 100%;
    }
  }
}

.profile {
  display: grid;
  grid-template-columns: 4.2rem 1fr 1rem;
  gap: 1.5rem;
  padding: 1rem 1.5rem;
  border: 0.1rem solid #e8e8e8;
  border-radius: 0.5rem;
  align-items: center;
  cursor: pointer;

  .profilePicture {
    width: 4rem;
    height: 4rem;
  }

  .texts {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;

    .name {
      grid-column: 1 / 3;
      text-transform: uppercase;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      word-break: break-word;
    }
  }

  .arrowIcon {
    width: 1rem;
  }
}

.menu {
  width: 100%;
  border-radius: 0.5rem;
  background-color: #ffffff;
  display: block;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);

  .menuHeader {
    padding: 1.5rem;
    border-bottom: 0.2rem solid #f3f3f3;
  }

  .menuBody {
    padding: 0 1.5rem;
    max-height: 37rem;
    overflow-y: scroll;
    overflow-x: hidden;

    & > div {
      border-bottom: 0.2rem solid #f3f3f3;

      &:last-child {
        border-bottom: none;
      }
    }

    .organization {
      cursor: pointer;
      padding: 1.5rem 0;
    }
  }
}
