@use '../../../../styles/variables';

.form {
  width: 100%;
  max-width: 51rem;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .row {
    position: relative;
    gap: 0.7rem;
  }

  .errorHelper {
    position: absolute;
    bottom: -17px;
    margin: 0;
  }

  .displayName {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .form {
    .link {
      grid-template-columns: 1fr;
    }

    .displayName {
      grid-template-columns: 1fr;
      gap: 0.7rem;
    }
  }
}
