.container {
    .deviceButton {
        width: 45px;
        height: 45px;
        margin-right: 0;
    }

    .gearIcon {
        font-size: 2.6rem !important;
    }

    .settingsMenuPopper {
        width: 330px;
    }
}