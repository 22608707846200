@use '../../../../styles/variables';

.welcomeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  .innerBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2.5rem;
    width: 29.5rem;

    .welcomeText {
      line-height: 3.2rem;
      text-align: center;

      .link {
        color: #81cfdd;
        font-weight: 700;
        font-style: italic;
      }
    }

    .image {
      width: 100%;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'md')) {
  .welcomeContainer .innerBox button {
    width: 100%;
  }
}
@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .welcomeContainer .innerBox {
    gap: 2rem;
    width: 22.5rem;

    .welcomeText {
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
  }
}
