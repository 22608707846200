@use '../../styles//variables';

.invitationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding: 20px;
  width: 570px;
  margin: 0 auto;
  color: #393534;

  .wantToKnowHyppaText {
    text-align: center;
    margin-top: 20px;

    a {
      text-decoration: underline;
      margin: 0;
      cursor: pointer;
    }
  }

  .invitationInnerContainer {
    display: flex;
    gap: 20px;
    text-align: center;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    padding: 30px 90px;
    .invitationImage {
      width: 200px;
      height: 150px;
      margin: 0 auto;
    }
    .buttonsContainerItemNoValid {
      width: 188px;
      margin: 0 auto;
    }
    .buttonsContainer {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      .buttonsContainerItem {
        flex: 1;
      }
    }
    .invitationTitle {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .invitationContainer {
    width: 100%;
    .invitationInnerContainer {
      padding: 20px;
    }
  }
}
