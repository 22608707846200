.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  gap: 1.5rem;

  width: calc(100% - 3rem);
  height: fit-content;
  margin: 3rem auto 0;

  background: #ffffff;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem;

  .icon {
    font-size: 6rem;
  }

  .formButton {
    width: 100%;
  }
}
