.preview {
    display: flex;
    overflow: hidden;
    width: 240px;
    height: 100%;
    max-height: 160px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    & video {
        height: 100%;
    }
}