@use '../../../../styles/variables';

.container {
  align-items: center;
  justify-content: center;
  gap: 20px;

  .deviceButton {
    width: 45px;
    height: 45px;
    margin-right: 0;
  }

  .audioIcon {
    font-size: 2.5rem !important;
  }

  .videoIcon {
    font-size: 2.6rem !important;
  }

  .shareIcon {
    font-size: 2.3rem !important;
  }

  .gearIcon {
    font-size: 2.6rem !important;
  }

  .moreIcon {
    font-size: 2.5rem !important;
    rotate: -90deg;
  }

  .dotMenuDeviceButton {
    width: 100%;
    height: 16px;
    justify-content: flex-start;
    padding: 0;
    background-color: transparent !important;
    border-radius: unset;

    p {
      color: #fff;
    }
  }

  .dotMenuShareIcon {
    font-size: 1.6rem !important;
  }

  .popperMobile {
    width: calc(100% - 20px);
    transform: translate3d(10px, -60px, 0) !important;
  }
}

.containerMobile {
  .dotMenuDeviceButton {
    p {
      color: #393534;
    }
  }
}

@media (max-width: map-get(variables.$breakpoints, 'sm')) {
  .container {
    gap: 1.5rem;
  }
}
